export const CONSTANT_PAYMENT_METHODS = [
    {
        type: '1',
        methodName: 'payment_methods_cash',
        printName: 'NUMERAR',
    },
    {
        type: '2',
        methodName: 'credit_card',
        printName: 'CARD',
    },
    {
        type: '3.1',
        methodName: 'payment_methods_voucher',
        printName: 'VAUCHER',
    },
    {
        type: '3.2',
        methodName: 'payment_methods_certificate',
        printName: 'CEC',
    },
    {
        type: '3.3',
        methodName: 'payment_methods_ticket',
        printName: 'TICHET',
    },
    {
        type: '5',
        methodName: 'payment_methods_food_voucher',
        printName: 'TME',
    },
    {
        type: '6',
        methodName: 'payment_methods_subscription',
        printName: 'ABONAMENT',
    },
    {
        type: '7',
        methodName: 'payment_methods_other_payment_instrument',
        printName: 'ALT_TIP',
    },
    {
        type: '8.1',
        methodName: 'payment_methods_credit',
        printName: 'CREDIT',
    },
    {
        type: '8.2',
        methodName: 'payment_methods_leasing',
        printName: 'LEASING',
    },
    {
        type: '8.3',
        methodName: 'payment_methods_prepaid_expense',
        printName: 'AVANS',
    },
    {
        type: '8.4',
        methodName: 'payment_methods_arvuna',
        printName: 'ARVUNA',
    },
    {
        type: '8.5',
        methodName: 'payment_methods_gaj',
        printName: 'GAJ',
    },
    {
        type: '8.8',
        methodName: 'payment_methods_compensation',
        printName: 'COMPENSARE',
    },
    {
        type: '8.9',
        methodName: 'payment_methods_alt_mod',
        printName: 'ALT_MOD',
    },
];

export const CONSTANT_PAYMENT_METHODS_DICT = CONSTANT_PAYMENT_METHODS.reduce((acc, item) => {
    acc[item.type] = item;
    return acc;
  }, {});

export const PaymentTypes = {
    CASH: '1',
    CARD: '2',
    VOUCHER: '3.1',
    CERTIFICAT: '3.2',
    TICKET: '3.3',
    FOOD_COUPON: '5',
    ABONAMENT: '6',
    ALT_PAYMENT: '7',
    CREDIT: '8.1',
    LEASING: '8.2',
    AVANS: '8.3',
    ARVUNA: '8.4',
    GAJ: '8.5',
    COMPENSATION: '8.8',
    ALT_MOD: '8.9',
};

const {
    CASH,
    CARD,
    CERTIFICAT,
    FOOD_COUPON,
    ABONAMENT,
    CREDIT,
    LEASING,
    AVANS,
    ARVUNA,
    GAJ,
    COMPENSATION,
    ALT_MOD,
} = PaymentTypes;

export const DiscountPaymentTypes = [CERTIFICAT, ABONAMENT, AVANS, ARVUNA];
export const DigitalPaymentTypes = [
    CARD,
    FOOD_COUPON,
    CREDIT,
    LEASING,
    GAJ,
    COMPENSATION,
    ALT_MOD,
];
export const BasicPaymentTypes = [...DigitalPaymentTypes, CASH];

export const DigitalBasicPaymentTypes = [CARD, FOOD_COUPON];

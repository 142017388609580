import React, { useRef } from 'react';
import { useTranslation } from 'react-i18next';
import QRCode from "react-qr-code";

import { useReactToPrint } from 'react-to-print';
import CircularProgress from '@mui/material/CircularProgress';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Card from '@mui/material/Card';
import Divider from '@mui/material/Divider';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import SendIcon from '@mui/icons-material/Send';
import PrintIcon from '@mui/icons-material/Print';
import QrCodeIcon from '@mui/icons-material/QrCode';
import LinkIcon from '@mui/icons-material/Link';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Snackbar from '@mui/material/Snackbar';
import Alert from '@mui/material/Alert';
import FormControl from '@mui/material/FormControl';
import Input from '@mui/material/Input';
import InputLabel from '@mui/material/InputLabel';

import { useParams, useSearchParams, useNavigate } from "react-router-dom";

import { QR_CODE_LINK } from '../../constants';
import { CONSTANT_PAYMENT_METHODS_DICT } from '../constants';
import { getReceiptById } from '../../services/requests/ApiRequests';
// import { resendNotification } from '../../services/requests/CreateNewReceipt';

const resendNotification = (param, apiKey) => {
  return new Promise((resolve, reject) => {
    setTimeout(() => {
      if (param.email || param.phone) {
        resolve({ data: { errors: [] } });
      } else {
        reject({ data: { errors: [{ message: "Invalid input" }] } });
      }
    }, 1000);
  });
};

export default function Receipt(props) {
  const { t } = useTranslation();
  const [organization, setOrganization] = React.useState({
    cashRegisters: [],
  });
  const [userName, setUserName] = React.useState('');
  // React.useEffect(() => {
  //     setOrganization(props.organizationAndUser.organization); 
  //     setUserName(props.organizationAndUser.user);
  // }, [props])

    const params = useParams();
    const [searchParams] = useSearchParams();
    const searchParamsObj = Object.fromEntries(searchParams);
    const navigate = useNavigate();
    const componentRef = useRef();
    const handlePrint = useReactToPrint({
      content: () => componentRef.current,
    });
    const [receipt, setReceipt] = React.useState({});
    const [loading, setLoading] = React.useState(true);
    const [totalTaxRate, setTotalTaxRate] = React.useState(0);

    const [subtotal, setSubtotal] = React.useState(0);

    const [open, setOpen] = React.useState(false);
    const [openSendForm, setOpenSendForm] = React.useState(false);
    const [notificationData, setNotificationData] = React.useState({
      phone: '',
      email: '',
    })

    const [mevID, setMevID] = React.useState('');
    const [error, setError] = React.useState({
      status: false,
      msg: "Error"
    });
    const [openSuccess, setOpenSuccess] = React.useState(false);

    const payments = receipt?.soap_data?.data?.payments?.field || [];
    const tichetPayment = payments.find(payment => payment.type === "TICHET") || {};
    const { type: firstPaymentType, deposit: firstPaymentDeposit } = tichetPayment;
    
    const isTichetType = firstPaymentType === "TICHET";

    const {change: generalChange, total} = receipt?.soap_data?.data || {}
    const changeAmount = isTichetType ? Math.max(firstPaymentDeposit - total, 0).toFixed(2) : generalChange
    
    React.useEffect(() => {
      getReceiptById(params.receiptId, searchParamsObj)
        .then((data) => {
          if(data.data !== null){
            const { payments, total } = data.data.soap_data.data;
            const { activities } = data.data.soap_data;
            setReceipt(data.data);
            setMevID(data.data.mevId);
            const totalTaxRate = activities.taxes.field.reduce((currentSum, tax) => {
              return parseFloat(currentSum) + parseFloat(tax.rate);
            }, 0);
          
            setTotalTaxRate(totalTaxRate.toFixed(2));
            const notPaidInCash = payments.field.reduce((currentSum, pm) => {
              return pm.type != "NUMERAR" ? 
                parseFloat(currentSum) + parseFloat(pm.deposit) : 0;
            }, 0)
            let sub = 0;
            sub = Math.max(parseFloat(total) - notPaidInCash, 0)
            setSubtotal(sub.toFixed(2));
            setLoading(false);
          } else {
            let msgError = "There are errors while receipt generation"
            if (data.errors.length > 0){
              msgError = data.errors[0].message;
            }
            setError({
                status: true,
                msg:  msgError,
            });
            setLoading(false);
          }
        });
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])
    
    function openLinkInNewTab(){
      const baseUrl = `${QR_CODE_LINK}${receipt?.soap_data.card.number}/${receipt?.soap_data.data.total}/${params.receiptId}/${receipt?.soap_data.datetime.split('T')[0]}`
      const newWindow = window.open(baseUrl, '_blank', 'noopener,noreferrer')
      if (newWindow) newWindow.opener = null
    }

    const showQR = () => {
      setOpen(true);
    };
  
    const handleClose = () => {
      setOpen(false);
      setOpenSendForm(false);
    };

    const handleCloseError = (event, reason) => {
      if (reason === 'clickaway') {
        return;
      }
  
      setError({
        status: false,
        msg: "Error"
      });
      navigate("/client/cash-logs");
    };

    const handleSendEmailSms = (e) => {
      setOpenSendForm(true);
    }

    const handleSendNotification = () => {
      const param = {
        _id: parseInt(params.receiptId, 10),
        email: notificationData.email,
        phone: notificationData.phone,
      }

      if (notificationData.email || notificationData.phone) {
        resendNotification(param, props.organizationAndUser.userCashRegisters[0].apiKey)
        .then(data => {
            if(data.data.errors.length === 0) {
                setOpenSuccess(true);
            } else {
                setError({
                    status: true,
                    msg:  "There are errors while sending receipt"
                });
            }
        })
        .catch(error => {
            setOpenSuccess(true);
            setError({
                status: true,
                msg:  "There are errors while sending receipt"
            });
        })
      } else {

      }
    }

    const cash = parseFloat(
      receipt?.soap_data?.data.payments.field
        .find(payment => payment.type === "1")?.deposit || 0
    )

    if (loading) {
      return (
        <div align='center'>
            <CircularProgress />
        </div>
      )
    }

    return (
    <Box sx={{ flexGrow: 1, marginTop: 5, marginLeft: 10 }}>
      <Grid container spacing={2} align='left'>
        <Grid item xs={5}>
          <Typography sx={{ fontSize: 28 }} color="pimary" gutterBottom>
            {t('viewing_receipt')}
          </Typography>
          <Button variant="text" color="secondary" onClick={() => { navigate("/acps/cash-logs");}}>&#60; {t('back_to_receipt_journal_btn')}</Button>
          <Divider />
          <Grid container spacing={2} align='left'>
            <Grid item xs={8}>
              <Typography sx={{ fontSize: 20 }} color="pimary" gutterBottom>
                {t('information_about_check')}
              </Typography>
            </Grid>
            <Grid item xs={4}>
              <Typography sx={{ fontSize: 20 }} color="pimary" gutterBottom>
                {receipt?.status}
              </Typography>
            </Grid>

            <Grid item xs={6}>
              <Typography sx={{ fontSize: 14 }} color="text.secondary" gutterBottom>
                {t('receipt_number')}:
              </Typography>
            </Grid>
            <Grid item xs={6}>
              <Typography sx={{ fontSize: 14 }} color="pimary" gutterBottom>
                {receipt?.soap_data.receipt}
              </Typography>
            </Grid>

            <Grid item xs={6}>
              <Typography sx={{ fontSize: 14 }} color="text.secondary" gutterBottom>
                {t('formed_date')}:
              </Typography>
            </Grid>
            <Grid item xs={6}>
              <Typography sx={{ fontSize: 14 }} color="pimary" gutterBottom>
                {receipt?.soap_data.datetime.split('T')[0]}  {receipt?.soap_data.datetime.split('T')[1]}
              </Typography>
            </Grid>         

            <Grid item xs={6}>
              <Typography sx={{ fontSize: 14 }} color="text.secondary" gutterBottom>
                {t('kkm_name')}
              </Typography>
            </Grid>
            <Grid item xs={6}>
              <Typography sx={{ fontSize: 14 }} color="pimary" gutterBottom>
                {receipt?.soap_data.card.name}
              </Typography>
            </Grid>           

            <Grid item xs={6}>
              <Typography sx={{ fontSize: 14 }} color="text.secondary" gutterBottom>
                {t('serial_number_kkm')}
              </Typography>
            </Grid>
            <Grid item xs={6}>
              <Typography sx={{ fontSize: 14 }} color="pimary" gutterBottom>
                {receipt?.soap_data.card.number}
              </Typography>
            </Grid>

            <Grid item xs={6}>
              <Typography sx={{ fontSize: 14 }} color="text.secondary" gutterBottom>
                {t('address_kkm')}
              </Typography>
            </Grid>
            <Grid item xs={6}>
              <Typography sx={{ fontSize: 14 }} color="pimary" gutterBottom>
                {receipt?.soap_data.card.address}
              </Typography>
            </Grid>
            <Grid item xs={12} align='center'>

              <Button variant="outlined" 
              sx={{ width: '75%' }} 
              startIcon={<PrintIcon />}
              onClick={handlePrint}
              disabled={receipt === undefined}
              >
                {t('print_receipt')}
              </Button>
            </Grid>
            <Grid item xs={12} align='center'>

              <Button 
                variant="outlined" 
                sx={{ width: '75%' }} 
                disabled={receipt === undefined} 
                endIcon={<SendIcon />}
                onClick={handleSendEmailSms}
              >
                {t('send_email_sms_btn')}
              </Button>
            </Grid>
            <Grid item xs={12} align='center'>

              <Button variant="outlined" 
              sx={{ width: '75%' }} 
              startIcon={<QrCodeIcon />}
              onClick={showQR}
              disabled={receipt === undefined}
              >
                {t('show_qr_btn')}
              </Button>
            </Grid>
            <Grid item xs={12} align='center'>

              <Button variant="outlined" 
              sx={{ width: '75%' }} 
              startIcon={<LinkIcon />}
              onClick={openLinkInNewTab}
              disabled={receipt === undefined}
              >
                {t('go_to_sfs_btn')}
              </Button>
            </Grid>
          </Grid>


        </Grid>
        <Grid item xs={6}>
        <Card sx={{ width: 375 }} ref={componentRef}>
                    <CardContent>

                        <Typography variant="h5" component="h5" color="text.secondary" align='center'>
                        {receipt?.soap_data.card.name}
                        </Typography>
                        <Typography sx={{ fontSize: 14 }} color="text.secondary" align='center' gutterBottom>
                        COD FISCAL: {receipt?.soap_data.card.idnx}
                        </Typography>
                        <Typography sx={{ fontSize: 14 }} color="text.secondary" align='center' gutterBottom>
                        {receipt?.soap_data.card.address}
                        </Typography>
                        <Grid container spacing={2} align='left'>
                            <Grid item xs={8}>
                                <Typography sx={{ fontSize: 14 }} color="text.secondary" gutterBottom>
                                NUMARUL DE INREGISTRARE:
                                </Typography>
                            </Grid>
                            <Grid item xs={4} align='right'>
                                <Typography sx={{ fontSize: 14 }} color="text.secondary" gutterBottom>
                                {receipt?.soap_data.card.number}
                                </Typography>
                            </Grid>
                        </Grid>

                        <Grid container spacing={2} align='left'>
                            <Grid item xs={8}>
                                <Typography sx={{ fontSize: 14 }} color="text.secondary" gutterBottom>
                                SN:
                                </Typography>
                            </Grid>
                            <Grid item xs={4} align='right'>
                                <Typography sx={{ fontSize: 14 }} color="text.secondary" gutterBottom>
                                {receipt?.soap_data.card.factory}
                                </Typography>
                            </Grid>
                        </Grid>

                        <Grid container spacing={2} align='left'>
                            <Grid item xs={8}>
                                <Typography sx={{ fontSize: 14 }} color="text.secondary" gutterBottom>
                                OPERATOR:
                                </Typography>
                            </Grid>
                            <Grid item xs={4} align='right'>
                                <Typography sx={{ fontSize: 14 }} color="text.secondary" gutterBottom>
                                {receipt?.soap_data.card.point}
                                </Typography>
                            </Grid>
                        </Grid>
                        <Divider />
                        {receipt?.soap_data.activities.field.map((product) => {
                            return(
                                <div key={product.name}>
                                    <Grid container spacing={2} align='left'>
                                        <Grid item xs={6}>
                                            <Typography sx={{ fontSize: 14, display: "block", wordWrap: "break-word" }} color="text.secondary" gutterBottom>
                                            {product.name}
                                            </Typography>
                                        </Grid>
                                        <Grid item xs={2}>
                                            <Typography sx={{ fontSize: 14 }} color="text.secondary" gutterBottom>
                                            {product.amount}
                                            </Typography>
                                        </Grid>
                                        <Grid item xs={1}>
                                            <Typography sx={{ fontSize: 14 }} color="text.secondary" gutterBottom>
                                            X
                                            </Typography>
                                        </Grid>
                                        <Grid item xs={2}>
                                            <Typography sx={{ fontSize: 14 }} color="text.secondary" gutterBottom>
                                            {product.price}
                                            </Typography>
                                        </Grid>
                                    </Grid>
                                    <Grid container spacing={2} align='left'>
                                        <Grid item xs={6}>
                                        </Grid>
                                        <Grid item xs={2}>
                                        </Grid>
                                        <Grid item xs={1}>
                                        </Grid>
                                        <Grid item xs={2}>
                                            <Typography sx={{ fontSize: 14 }} color="text.secondary" gutterBottom>
                                            {product.cost}
                                            </Typography>
                                        </Grid>
                                        <Grid item xs={1}>
                                            <Typography sx={{ fontSize: 14 }} color="text.secondary" gutterBottom>
                                            {product.tax.code}
                                            </Typography>
                                        </Grid>
                                    </Grid>
                                    {product?.others?.field?.length > 0 &&
                                      <Grid container spacing={2} align='left'>
                                          <Grid item xs={6}>
                                              <Typography sx={{ fontSize: 14, display: "block", wordWrap: "break-word" }} color="text.secondary" gutterBottom>
                                                {t('discount')}
                                              </Typography>
                                          </Grid>
                                          <Grid item xs={3}>
                                              <Typography sx={{ fontSize: 14 }} color="text.secondary" gutterBottom>
                                                {product?.others?.field?.[0]?.percent}{' '}%
                                              </Typography>
                                          </Grid>
                                          <Grid item xs={1}>
                                              <Typography sx={{ fontSize: 14 }} color="text.secondary" gutterBottom>
                                              {product?.others?.field?.[0]?.rate}
                                              </Typography>
                                          </Grid>
                                      </Grid>
                                    }
                                </div>
                            )
                        })}

                        <Divider />
                        <Grid container spacing={2} align='left'>
                            <Grid item xs={9}>
                                <Typography sx={{ fontSize: 14, fontWeight: 600 }} color="text.secondary" gutterBottom>
                                TOTAL:
                                </Typography>
                            </Grid>
                            <Grid item xs={2}>
                                <Typography sx={{ fontSize: 14, fontWeight: 600 }} color="text.secondary" gutterBottom>
                                {receipt?.soap_data.data.total}
                                </Typography>
                            </Grid>
                        </Grid>


                        {receipt?.soap_data.activities.taxes.field.map((tax) => {
                            return(
                                <Grid key={tax.code} container spacing={2} align='left'>
                                    <Grid item xs={9}>
                                        <Typography sx={{ fontSize: 14 }} color="text.secondary" gutterBottom>
                                          TVA {tax.code} {tax.code === 'F' ? t('no_vat') : `${tax.percent} %`}
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={2}>
                                        <Typography sx={{ fontSize: 14 }} color="text.secondary" gutterBottom>
                                        {tax.rate}
                                        </Typography>
                                    </Grid>

                                </Grid>
                            )
                        })}

                        <Grid container spacing={2} align='left'>
                            <Grid item xs={9}>
                                <Typography sx={{ fontSize: 14 }} color="text.secondary" gutterBottom>
                                    TVA TOTAL:
                                </Typography>
                            </Grid>
                            <Grid item xs={2}>
                                <Typography sx={{ fontSize: 14 }} color="text.secondary" gutterBottom>
                                {totalTaxRate}
                                </Typography>
                            </Grid>
                        </Grid>

                        <Divider />


                        {receipt?.soap_data.data.payments.field.map(pt => {
                          const { type: payType, deposit: payDeposit } = pt
                          const isNumerarType = payType === "NUMERAR";
                          if(!isNumerarType) {
                            return(
                              <Grid key={payType} container spacing={2} align='left'>
                                  <Grid item xs={9}>
                                      <Typography sx={{ fontSize: 14 }} color="text.secondary" gutterBottom>
                                          {CONSTANT_PAYMENT_METHODS_DICT[payType].printName}:
                                      </Typography>
                                  </Grid>
                                  <Grid item xs={2}>
                                      <Typography sx={{ fontSize: 14 }} color="text.secondary" gutterBottom>
                                          {payDeposit}
                                      </Typography>
                                  </Grid>
                              </Grid>
                            )
                          }
                        })}
                            <Grid container spacing={2} align='left'>
                              <Grid item xs={9}>
                                  <Typography sx={{ fontSize: 14 }} color="text.secondary" gutterBottom>
                                    SUBTOTAL:
                                  </Typography>
                              </Grid>
                              <Grid item xs={2}>
                                  <Typography sx={{ fontSize: 14 }} color="text.secondary" gutterBottom>
                                   {subtotal}
                                  </Typography>
                              </Grid>
                          </Grid>


                          <Grid container spacing={2} align='left'>
                              <Grid item xs={9}>
                                  <Typography sx={{ fontSize: 14 }} color="text.secondary" gutterBottom>
                                  NUMERAR:
                                  </Typography>
                              </Grid>
                              <Grid item xs={2}>
                                  <Typography sx={{ fontSize: 14 }} color="text.secondary" gutterBottom>
                                      {cash.toFixed(2)}
                                  </Typography>
                              </Grid>
                          </Grid>

                            <Grid container spacing={2} align='left'>
                                <Grid item xs={9}>
                                    <Typography sx={{ fontSize: 14 }} color="text.secondary" gutterBottom>
                                    {isTichetType ? 'REST TMH' : 'REST'}:
                                    </Typography>
                                </Grid>
                                <Grid item xs={2}>
                                    <Typography sx={{ fontSize: 14 }} color="text.secondary" gutterBottom>
                                        {changeAmount}
                                    </Typography>
                                </Grid>
                            </Grid>

                            <Grid container spacing={2} align='left'>
                                <Grid item xs={9}>
                                    <Typography sx={{ fontSize: 14 }} color="text.secondary" gutterBottom>
                                    Articole:
                                    </Typography>
                                </Grid>
                                <Grid item xs={2}>
                                    <Typography sx={{ fontSize: 14 }} color="text.secondary" gutterBottom>
                                    {receipt?.soap_data.activities.field.length?.toFixed(2)}
                                    </Typography>
                                </Grid>
                            </Grid>

                        <Divider />
                        <Grid container spacing={2} align='left'>
                            <Grid item xs={8}>
                                <Typography sx={{ fontSize: 14 }} color="text.secondary" gutterBottom>
                                  DATA: {receipt?.soap_data.datetime.split('T')[0]}
                                </Typography>
                            </Grid>
                            <Grid item xs={4} align='right'>
                                <Typography sx={{ fontSize: 14 }} color="text.secondary" gutterBottom>
                                  ORA: {receipt?.soap_data.datetime.split('T')[1]}
                                </Typography>
                            </Grid>
                        </Grid>

                        <Grid container spacing={2} align='left'>
                            <Grid item xs={9}>
                                <Typography sx={{ fontSize: 14 }} color="text.secondary" gutterBottom>
                                BON FISCAL:
                                </Typography>
                            </Grid>
                            <Grid item xs={3} align='right'>
                                <Typography sx={{ fontSize: 14 }} color="text.secondary" gutterBottom>
                                №: {receipt?.soap_data.receipt}
                                </Typography>
                            </Grid>
                        </Grid>

                        <Divider />

                        <Grid container spacing={2} align='left'>
                            <Grid item xs={12} align='right'>
                                <Typography sx={{ fontSize: 14 }} color="text.secondary" gutterBottom>
                                {receipt?.id}
                                </Typography>
                            </Grid>
                        </Grid>

                        <Grid container spacing={2} align='left'>
                          <Grid item xs={6}>
                            <Typography sx={{ fontSize: 14 }} color="text.secondary" gutterBottom>
                                MEV ID:
                            </Typography>
                          </Grid>
                          <Grid item xs={6} align='right'>
                            <Typography sx={{ fontSize: 14 }} color="text.secondary" gutterBottom>
                                {receipt?.soap_response?.id}
                            </Typography>
                          </Grid>
                        </Grid>

                        <Grid container spacing={2} align='center'>
                          <Grid item xs={12}>
                          </Grid>
                          <Grid item xs={12}>
                              <div id='qrcode'>
                                <QRCode size={80} value={`${QR_CODE_LINK}${receipt?.soap_data.card.number}/${receipt?.soap_data.data.total}/${params.receiptId}/${receipt?.soap_data.datetime.split('T')[0]}`} />
                              </div>
                          </Grid>
                          <Grid item xs={12}>
                          </Grid>
                        </Grid>
                        <Divider />
                        <Grid container spacing={2} align='center'>
                          <Grid item xs={12}>
                              <Typography sx={{ fontSize: 14 }} color="text.secondary" gutterBottom>
                                ***  BON FISCAL  ***
                              </Typography>
                          </Grid>
                        </Grid>

                    </CardContent>

                </Card>
        </Grid>
      </Grid>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          {t('qr_code_description')}
        </DialogTitle>
        <DialogContent>
          <div id='qrcode'>
            <QRCode value={`${QR_CODE_LINK}${receipt?.soap_data.card.number}/${receipt?.soap_data.data.total}/${params.receiptId}/${receipt?.soap_data.datetime.split('T')[0]}`} />
          </div>
        </DialogContent>
      </Dialog>

      <Dialog
        open={openSendForm}
        onClose={handleClose}
        aria-labelledby="send-dialog-title"
        aria-describedby="send-dialog-description"
      >
        <DialogTitle id="send-dialog-title" align="center" variant="h5">
           {t('sent_email_sms_title')}
        </DialogTitle>
        <DialogContent>
          <FormControl
              fullWidth
              sx={{ m: 1 }}
              variant='standard'
          >
              <InputLabel htmlFor='component-simple'>
                  {t('phone')}
              </InputLabel>
              <Input
                  id='component-simple'
                  name='phone'
                  onChange={e => setNotificationData({...notificationData, phone: e.target.value})}
              />
          </FormControl>
          <FormControl
              fullWidth
              sx={{ m: 1 }}
              variant='standard'
          >
              <InputLabel htmlFor='component-simple'>
                  {t('email')}
              </InputLabel>
              <Input
                  id='component-simple'
                  name='email'
                  onChange={e => setNotificationData({...notificationData, email: e.target.value})}
              />
          </FormControl>
          <Button
            variant='outlined'
            sx={{
                marginTop: 2,
                color: '#adadad',
                borderColor: '#adadad',

            }}
            onClick={handleClose}
          >
              {t('Cancel')}
          </Button>
          <Button
            variant='outlined'
            sx={{
                marginTop: 2,
                marginLeft: 2
            }}
            onClick={handleSendNotification}
          >
              {t('send_email_sms_btn')}
          </Button>
        </DialogContent>
      </Dialog>

      <Snackbar
          open={openSuccess}
          autoHideDuration={6000}
          onClose={handleClose}
      >
          <Alert
              onClose={handleClose}
              severity='success'
              sx={{ width: '100%' }}
          >
              {t('receipt_send_msg')}
          </Alert>
      </Snackbar>

      <Snackbar open={error.status} autoHideDuration={6000} onClose={handleCloseError}>
          <Alert onClose={handleCloseError} severity="error" sx={{ width: '100%' }}>
              {error.msg}
          </Alert>
      </Snackbar>
    </Box>
  )
}

import React from 'react';

const CalendarIcon = () => {
    return (
        <svg
            width='26'
            height='26'
            viewBox='0 0 26 26'
            fill='none'
            xmlns='http://www.w3.org/2000/svg'
        >
            <path
                d='M8.49826 3.99634V6.99759'
                stroke='#313238'
                strokeWidth='1.5'
                strokeLinecap='round'
                strokeLinejoin='round'
            />
            <path
                d='M17.5022 3.99634V6.99759'
                stroke='#313238'
                strokeWidth='1.5'
                strokeLinecap='round'
                strokeLinejoin='round'
            />
            <path
                d='M3.99609 10.499H22.0036'
                stroke='#313238'
                strokeWidth='1.5'
                strokeLinecap='round'
                strokeLinejoin='round'
            />
            <rect
                x='3.99609'
                y='5.49707'
                width='18.0075'
                height='16.5069'
                rx='3'
                stroke='#313238'
                strokeWidth='1.5'
                strokeLinecap='round'
                strokeLinejoin='round'
            />
        </svg>
    );
};

export default CalendarIcon;

import React, { useRef, useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import FormControl from '@mui/material/FormControl';
import Input from '@mui/material/Input';
import InputLabel from '@mui/material/InputLabel';
import TextField from '@mui/material/TextField';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import Card from '@mui/material/Card';
import Divider from '@mui/material/Divider';
import CardContent from '@mui/material/CardContent';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';
import Alert from '@mui/material/Alert';
import Snackbar from '@mui/material/Snackbar';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import CircularProgress from '@mui/material/CircularProgress';
import PrintIcon from '@mui/icons-material/Print';
import AddIcon from '@mui/icons-material/Add';
import DeleteForeverOutlinedIcon from '@mui/icons-material/DeleteForeverOutlined';

import {
    createNewReceipt,
    createNewFiscalReceipt,
    // getMevIdForByReceiptId,
} from '../../services/requests/CreateNewReceipt';
// import { getReceiptById } from '../../services/requests/GraphRequests';
import QRCode from 'react-qr-code';
import { useReactToPrint } from 'react-to-print';
import {
    CONSTANT_PAYMENT_METHODS,
    PaymentTypes,
    DigitalPaymentTypes,
    DigitalBasicPaymentTypes,
    BasicPaymentTypes,
    DiscountPaymentTypes,
} from '../constants';

import CashRegisterForm from './CashRegisterForm';

export default function NewReceipt(props) {
    const { t } = useTranslation();
    const [organization, setOrganization] = useState({});
    const [taxesRates, setTaxesRates] = useState([]);
    const [userName, setUserName] = useState('');
    const [newReceipt, setNewReceipt] = useState(null);
    const [isReceiptCreated, setIsReceiptCreated] = useState(false);
    const [card, setCard] = useState({});

    let navigate = useNavigate();

    const onSubmitCashRegister = data => {
        setCard(data.formData);
        setOrganization(data.organization);
        const taxes = data.organization?.cash_registers?.[0]?.taxes;
        setTaxesRates(taxes?.filter(el => el.enabled) || []);
    };

    const initialStateValue = {
        report: '',
        receipt: '',
        clientIDNO: '',
        clientName: '',
        clientAddress: '',
        productName: '',
        productAmount: '',
        productPrice: '',
        taxes: taxesRates?.map(a => ({ ...a })),

        paymentsType: [
            {
                type: '',
                deposit: '0.00',
            },
        ],

        activity: [
            {
                productName: '',
                productAmount: '',
                productPrice: '',
                productDiscountType: '',
                productDiscountRate: '',
                productDiscountPercent: '',
                // productDiscount: '',
                productVAT: 0,
                productTax: { code: '', percent: 0, rate: 0 },
                productCost: '',
            },
        ],
    };
    const [state, setState] = useState(initialStateValue);
    const [openSuccess, setOpenSuccess] = useState(false);
    const [error, setError] = useState({
        status: false,
        msg: 'Error',
    });
    const [client, setClient] = useState({
        clientPhone: '',
        clientEmail: '',
    });
    const [showQRBtn, setShowQRBtn] = useState(false);
    const [openQRDialog, setQRDialog] = useState(false);
    const [receiptUrl, setReceiptUrl] = useState('');

    const [showReceipt, setShowReceipt] = useState(false);
    const [loadingMevId, setLoadingMevId] = useState(false);

    const [mevID, setMevID] = useState('');

    const [totalCost, setTotalCost] = useState(0);
    const [totalDiscount, setTotalDiscount] = useState(0);

    const [totalTax, setTotalTax] = useState([]);
    const [totalTaxRate, setTotalTaxRate] = useState(0);

    const [clientMoney, setClientMoney] = useState(0);
    const [notPaidInCash, setNotPaidInCash] = useState(0);
    const [restMoney, setRestMoney] = useState(0);
    const [subtotal, setSubtotal] = useState(0);

    const [receiptView, setReceiptView] = useState(false);
    const [sameVAT, setSameVAT] = useState({});

    const digitalPaymentCount = state.paymentsType.filter(pt =>
        DigitalPaymentTypes.includes(pt.type),
    ).length;

    const isSingleDigitalPaymentType = digitalPaymentCount === 1;

    const hasMultiplePayments = state.paymentsType.length >= 2;

    const hasAdditionalPaymentType =
        hasMultiplePayments &&
        state.paymentsType.some(
            payment => !BasicPaymentTypes.includes(payment.type),
        );

    const hasActivity = !!state?.activity?.[0]?.productCost;

    const handleChangeClient = event => {
        setClient({
            ...client,
            [event.target.name]: event.target.value,
        });
    };

    function receiptValidation(t) {
        let err = [];

        if (card.address === '') {
            err.push(t('fe_valid_error_cash_re'));
        }
        state.activity.forEach(product => {
            if (product.productName === '') {
                err.push(t('fe_valid_error_product_name'));
            }
            if (
                parseFloat(product.productPrice) === 0 ||
                product.productPrice === 'NaN' ||
                product.productPrice === ''
            ) {
                err.push(t('fe_valid_error_product_price'));
            }
            if (
                product.productTax.rate === 'NaN' ||
                product.productTax.rate === ''
            ) {
                err.push(t('fe_valid_error_product_tax'));
            }
        });
        if (parseFloat(totalCost) === 0) {
            err.push(t('fe_valid_error_cost_0'));
        }
        if (
            state.paymentsType.length === 1 &&
            state.paymentsType[0].type === ''
        ) {
            err.push(t('fe_valid_error_not_choosed'));
        }
        state.paymentsType.forEach(pt => {
            if (pt.type === '' || pt.productPrice === 'NaN') {
                err.push(t('fe_valid_error_not_choosed_2'));
            }
            if (hasAdditionalPaymentType && (parseFloat(pt.deposit) >= parseFloat(totalCost))) {
                err.push(t('fe_valid_error_exceeded_payment_types'));
            }
        });
        if (parseFloat(notPaidInCash) <= 0) {
            if (parseFloat(clientMoney) < parseFloat(totalCost)) {
                err.push(t('fe_valid_error_need_more_money'));
            }
        }
        if (parseFloat(clientMoney) <= 0) {
            if (parseFloat(notPaidInCash) < parseFloat(totalCost)) {
                err.push(t('fe_valid_error_need_more_money'));
            }
        }
        if (parseFloat(clientMoney) > 0 && parseFloat(notPaidInCash) > 0) {
            if (parseFloat(clientMoney) < parseFloat(subtotal)) {
                err.push(t('fe_valid_error_need_more_money'));
            }
            // if (
            //     parseFloat(clientMoney) >= parseFloat(totalCost) ||
            //     parseFloat(notPaidInCash) >= parseFloat(totalCost)
            // ) {
            //     err.push(t('fe_valid_error_excess_client_money'));
            // }
        }
        return err;
    }

    useEffect(() => {
        let cost = 0;
        let discount = 0;
        /*
        taxesRates has reference type. When there is rerender, 
        we change allTaxes rates. allTaxes refers to taxesRates, 
        and thus changes taxesRates too.
        */

        const allTaxes = taxesRates.map(a => ({ ...a }));
        let filteredTaxesDict = [];

        state.activity.forEach(product => {
            cost = cost + parseFloat(product.productCost);
            discount = discount + parseFloat(product.productDiscountRate);

            allTaxes.forEach(tax => {
                if (tax.code === product.productTax.code) {
                    let rate =
                        (parseFloat(product.productCost) * tax.percent) /
                        (100 + tax.percent);
                    tax.rate = rate.toFixed(2);
                    filteredTaxesDict[tax.code] = tax;
                    product.productVAT =
                        filteredTaxesDict[product.productTax.code]?.rate || '';
                }
            });
        });

        const filteredTaxes = Object.values(filteredTaxesDict).sort((a, b) =>
            a.code.localeCompare(b.code),
        );

        if (cost) {
            setTotalCost(cost.toFixed(2));
        }

        if (discount) {
            setTotalDiscount(discount.toFixed(2));
        }

        setTotalTax(filteredTaxes);

        const totalTaxRate = Object.values(sameVAT).reduce(
            (currentSum, vat) => {
                return parseFloat(currentSum) + parseFloat(vat.total);
            },
            0,
        );

        setTotalTaxRate(totalTaxRate.toFixed(2));
        setReceiptView(false);
        setShowQRBtn(false);

        let notPaidInCash = 0;
        let clientMoney = 0;
        let subtotal = 0;
        let rest = 0;
        let totalOtherDeposits = 0;

        state.paymentsType.forEach(pt => {
            if (!DigitalBasicPaymentTypes.includes(pt.type)) {
                totalOtherDeposits += parseFloat(pt.deposit);
            }
        });

        state.paymentsType.forEach(pt => {
            if (pt.type !== '') {
                if (pt.type === PaymentTypes.CASH || pt.type === PaymentTypes.TICKET) {
                    clientMoney = parseFloat(pt.deposit);
                } else {
                    if (isSingleDigitalPaymentType) {
                        pt.deposit = Math.max(
                            cost - totalOtherDeposits,
                            0,
                        ).toFixed(2);
                    }
                    notPaidInCash = notPaidInCash + parseFloat(pt.deposit);
                }
            }
        });

        if (cost) {
            if (notPaidInCash) {
                subtotal = Math.max(cost - notPaidInCash, 0);
            } else {
                subtotal = cost;
            }
        }

        state.paymentsType.forEach(pt => {
            const { type } = pt;
            const hasDiscountType = DiscountPaymentTypes.includes(type);

            if (subtotal || !hasDiscountType) {
                rest = clientMoney - subtotal;
            }

            if (rest < 0 || isNaN(rest) || !type || hasDiscountType) {
                rest = 0;
            }
        });

        setClientMoney(clientMoney.toFixed(2));
        setRestMoney(rest.toFixed(2));
        setSubtotal(subtotal.toFixed(2));
        setNotPaidInCash(notPaidInCash.toFixed(2));
    }, [sameVAT, state, taxesRates]);

    useEffect(() => {
        let sameVAT = {};

        state.activity.forEach(product => {
            const { productTax, productVAT } = product;

            if (productTax && productTax.code) {
                const taxCode = productTax.code;

                if (sameVAT.hasOwnProperty(taxCode)) {
                    sameVAT[taxCode].total += parseFloat(productVAT);
                    sameVAT[taxCode].total = parseFloat(
                        sameVAT[taxCode].total.toFixed(2),
                    );
                } else {
                    sameVAT[taxCode] = {
                        total: parseFloat(productVAT),
                    };
                }
            }
        });

        setSameVAT(sameVAT);
    }, [state]);

    useEffect(() => {
        if (!(mevID === '')) {
            setShowReceipt(true);
            setLoadingMevId(false);
            setOpenSuccess(true);
        }
    }, [mevID]);

    const handleSendReceipt = () => {
        let activities = [];

        state.activity.forEach(product => {
            let activity = {
                amount: product.productAmount,
                price: product.productPrice,
                name: product.productName,
                cost: product.productCost,
                tax: {
                    percent: product.productTax.percent.toString(),
                    code: product.productTax.code || 'A',
                    rate: product.productVAT.toString(),
                },
            };
            if (product.productDiscountRate)
                activity = {
                    ...activity,
                    others: {
                        field: [
                            {
                                type: '2',
                                code: product.productTax.code,
                                percent: product.productDiscountPercent,
                                rate: product.productDiscountRate,
                            },
                        ],
                    },
                };

            activities.push(activity);
        });

        const sortedPayments = state?.paymentsType.sort(
            (a, b) => parseFloat(b.deposit) - parseFloat(a.deposit),
        );

        const taxesFieldObj = activities.reduce((acc, activity) => {
            const { tax } = activity;
            if (acc[tax.code]) {
                const { cost, rate } = acc[tax.code];
                acc[tax.code].cost = (parseFloat(cost) + parseFloat(activity.cost)).toString();
                acc[tax.code].rate = (parseFloat(rate) + parseFloat(tax.rate)).toString();
            } else {
                acc[tax.code] = {
                    code: tax.code,
                    percent: tax.percent,
                    rate: tax.rate,
                    cost: activity.cost,
                };
            }
            return acc;
        }
        , {});
        const taxesField = Object.values(taxesFieldObj);

        let param = {
            soap_data: {
                card: {
                    ...card,
                },
                data: {
                    total: totalCost.toString(),
                    payments: {
                        field: sortedPayments,
                    },
                    change: restMoney.toString(),
                },
                activities: {
                    field: activities,
                    taxes: {
                        field: taxesField,
                        // field: activities.map(a => ({
                        //     code: a.tax.code,
                        //     percent: a.tax.percent,
                        //     rate: a.tax.rate,
                        //     cost: a.cost,
                        // })),
                    },
                },
            },
            email: client.clientEmail,
            phone: client.clientPhone,
        }

        setLoadingMevId(true);

        createNewFiscalReceipt(param)
            .then(data => {
                if (data.data?.soap_response?.state === 'SUCCESS') {
                    setIsReceiptCreated(true);
                    setMevID(data.data.soap_response.id);
                    setNewReceipt(data.data);
                    setShowQRBtn(true);
                    setReceiptUrl(data.data.sfs_url);
                } else {
                    setError({
                        status: true,
                        msg:
                            data.data.soap_response.errors ||
                            'There are errors while receipt generation',
                    });
                }
            })
            .catch(error => {
                const data = error?.response?.data || {};
                const msg = data.error  || 'There are errors while receipt generation';
                setError({ status: true, msg });
                setLoadingMevId(false);
            });
    };

    const showQR = () => {
        setQRDialog(true);
    };

    const handleCloseQrDialog = () => {
        setQRDialog(false);
    };

    const handleClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }

        setOpenSuccess(false);
        setError({
            status: false,
            msg: 'Error',
        });
    };

    const handleViewReceipt = () => {
        let err = receiptValidation(t);

        if (err.length === 0) {
            setReceiptView(!receiptView);
        } else {
            setError({
                status: true,
                msg: err[0],
            });
        }
    };

    const componentRef = useRef();
    const handlePrint = useReactToPrint({
        content: () => componentRef.current,
    });

    function clickBackToCreation() {
        setReceiptView(false);
    }

    const isCashRegisterAvailable = !!Object.keys(card).length;

    const isTicketPaymentType = state.paymentsType.some(
        pt => pt.type === PaymentTypes.TICKET,
    );

    return (
        <Box
            sx={{ flexGrow: 1, marginTop: 3, marginLeft: 0, marginBottom: 10 }}
        >
            {!receiptView && (
                <>
                    <CashRegisterForm onSubmit={onSubmitCashRegister} />
                    {isCashRegisterAvailable && (
                        <>
                            <Typography
                                sx={{ fontSize: 24, marginTop: 6 }}
                                color='pimary'
                                gutterBottom
                            >
                                {t('adding_product')}:
                            </Typography>
                            <Products
                                products={state.activity}
                                setState={setState}
                                taxesRates={taxesRates}
                            />
                        </>
                    )}
                    {hasActivity && (
                        <>
                            <Typography
                                sx={{ fontSize: 24, marginTop: 6 }}
                                color='pimary'
                                gutterBottom
                            >
                                {t('total')}:
                            </Typography>
                            <Grid container spacing={2} align='left'>
                                <Grid item xs={6}>
                                    <PaymentsTypes
                                        paymentsType={state.paymentsType}
                                        restMoney={restMoney}
                                        setState={setState}
                                    />
                                    <br />
                                    <br />
                                    <Button
                                        variant='outlined'
                                        sx={{
                                            visibility: !receiptView
                                                ? 'visible'
                                                : 'hidden',
                                        }}
                                        onClick={handleViewReceipt}
                                    >
                                        {t('go_to_payment_btn')}
                                    </Button>
                                </Grid>
                                <Grid item xs={3}>
                                    <Grid container spacing={2} align='left'>
                                        <Grid item xs={12}>
                                            {t('taxes')}:
                                        </Grid>
                                        <Grid item xs={6}></Grid>
                                    </Grid>
                                    {!!totalTax &&
                                        totalTax?.map((tax, index) => {
                                            const { code: taxCode, percent } =
                                                tax;
                                            const totalVAT =
                                                sameVAT[taxCode]?.total.toFixed(
                                                    2,
                                                ) || 0;

                                            return (
                                                <Grid
                                                    container
                                                    spacing={2}
                                                    align='left'
                                                    key={`tax_${tax.code}_${index}`}
                                                >
                                                    <Grid item xs={1}>
                                                        {tax.code}
                                                    </Grid>
                                                    <Grid
                                                        item
                                                        xs={5}
                                                        style={{
                                                            whiteSpace:
                                                                'nowrap',
                                                        }}
                                                    >
                                                        {percent} %
                                                    </Grid>
                                                    <Grid
                                                        item
                                                        xs={4}
                                                        align='right'
                                                    >
                                                        {totalVAT}
                                                    </Grid>
                                                </Grid>
                                            );
                                        })}

                                    {totalTaxRate > 0 && (
                                        <>
                                            <Grid
                                                container
                                                spacing={2}
                                                align='left'
                                            >
                                                <Grid item xs={10}>
                                                    <Divider
                                                        style={{
                                                            background: 'black',
                                                        }}
                                                    />
                                                </Grid>
                                                <Grid item xs={6}>
                                                    {t('total')}
                                                </Grid>
                                                <Grid item xs={4} align='right'>
                                                    {totalTaxRate}
                                                </Grid>
                                            </Grid>
                                        </>
                                    )}
                                </Grid>
                                <Grid item xs={1}>
                                    <Grid container spacing={2} align='left'>
                                        <Grid item xs={12}>
                                            {t('discount')}:
                                        </Grid>
                                        <Grid item xs={6}>
                                            {totalDiscount}
                                        </Grid>
                                    </Grid>
                                </Grid>
                                <Grid item xs={1}>
                                    <Grid container spacing={2} align='left'>
                                        <Grid item xs={12}>
                                            {t('cost')}:
                                        </Grid>
                                        <Grid item xs={6}>
                                            {totalCost}
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </>
                    )}
                </>
            )}

            {receiptView && (
                <>
                    {/* get client data and send receipt to mev */}
                    <br /> <br />
                    <Button
                        variant='text'
                        color='secondary'
                        onClick={() => {
                            clickBackToCreation();
                        }}
                        disabled={showReceipt || loadingMevId}
                    >
                        &#60; {t('back_to_receipt_creation_btn')}
                    </Button>
                    <Divider />
                    <Grid
                        container
                        spacing={30}
                        sx={{ visibility: receiptView ? 'visible' : 'hidden' }}
                        align='left'
                    >
                        <Grid item xs={4}>
                            <Typography variant='h5' component='div'>
                                {t('buyer_data')}
                            </Typography>
                            <Divider />
                            <Typography
                                sx={{ fontSize: 12 }}
                                color='text.secondary'
                                gutterBottom
                            >
                                {t('helper_text_enter_email')}
                            </Typography>
                            <FormControl
                                fullWidth
                                sx={{ m: 1 }}
                                variant='standard'
                            >
                                <InputLabel htmlFor='component-simple'>
                                    {t('phone')}
                                </InputLabel>
                                <Input
                                    id='component-simple'
                                    value={client.clientPhone}
                                    onChange={handleChangeClient}
                                    name='clientPhone'
                                />
                            </FormControl>
                            <FormControl
                                fullWidth
                                sx={{ m: 1 }}
                                variant='standard'
                            >
                                <InputLabel htmlFor='component-simple'>
                                    {t('email')}
                                </InputLabel>
                                <Input
                                    id='component-simple'
                                    value={client.clientEmail}
                                    onChange={handleChangeClient}
                                    name='clientEmail'
                                />
                            </FormControl>
                            {!showReceipt && (
                                <Button
                                    variant='outlined'
                                    sx={{ marginTop: 2, width: '100%' }}
                                    onClick={handleSendReceipt}
                                    disabled={loadingMevId || isReceiptCreated}
                                >
                                    {isReceiptCreated
                                        ? t('success_creat_send')
                                        : t('creat_send')}
                                </Button>
                            )}
                            {!showReceipt && loadingMevId && (
                                <Button
                                    variant='outlined'
                                    sx={{ marginTop: 2, width: '100%' }}
                                    onClick={() => window.open('/client')}
                                >
                                    {t('new_receipt_in_new_tab_btn')}
                                </Button>
                            )}

                            <Button
                                variant='outlined'
                                sx={{
                                    marginTop: 2,
                                    width: '100%',
                                    visibility: showReceipt
                                        ? 'visible'
                                        : 'hidden',
                                }}
                                startIcon={<PrintIcon />}
                                onClick={handlePrint}
                                disabled={!showQRBtn && !showReceipt}
                            >
                                {t('print_receipt')}
                            </Button>

                            <Button
                                variant='outlined'
                                sx={{
                                    marginTop: 2,
                                    width: '100%',
                                    visibility: showReceipt
                                        ? 'visible'
                                        : 'hidden',
                                }}
                                disabled={!showQRBtn && !showReceipt}
                                onClick={showQR}
                            >
                                {t('show_qr_btn')}
                            </Button>

                            <Button
                                variant='outlined'
                                sx={{
                                    marginTop: 2,
                                    width: '100%',
                                    visibility: showReceipt
                                        ? 'visible'
                                        : 'hidden',
                                }}
                                disabled={!showReceipt}
                                onClick={() => {
                                    navigate(0);
                                }}
                            >
                                {t('new_receipt_btn')}
                            </Button>
                        </Grid>
                        <Grid item xs={8}>
                            {/* here receipt view*/}

                            {showReceipt && (
                                <Card sx={{ width: 375 }} ref={componentRef}>
                                    <CardContent>
                                        <Typography
                                            key='organization.name'
                                            variant='h5'
                                            component='h5'
                                            color='text.secondary'
                                            align='center'
                                        >
                                            {organization.name}
                                        </Typography>
                                        <Typography
                                            key='organization.idno'
                                            sx={{ fontSize: 14 }}
                                            color='text.secondary'
                                            align='center'
                                            gutterBottom
                                        >
                                            COD FISCAL: {organization.idno}
                                        </Typography>
                                        <Typography
                                            key='organization.address'
                                            sx={{ fontSize: 14 }}
                                            color='text.secondary'
                                            align='center'
                                            gutterBottom
                                        >
                                            {card.address}
                                        </Typography>
                                        <Grid
                                            container
                                            spacing={2}
                                            align='left'
                                        >
                                            <Grid item xs={8}>
                                                <Typography
                                                    sx={{ fontSize: 14 }}
                                                    color='text.secondary'
                                                    gutterBottom
                                                >
                                                    NUMARUL DE INREGISTRARE:
                                                </Typography>
                                            </Grid>
                                            <Grid item xs={4} align='right'>
                                                <Typography
                                                    sx={{ fontSize: 14 }}
                                                    color='text.secondary'
                                                    gutterBottom
                                                >
                                                    {card.number}
                                                </Typography>
                                            </Grid>
                                        </Grid>

                                        <Grid
                                            container
                                            spacing={2}
                                            align='left'
                                        >
                                            <Grid item xs={8}>
                                                <Typography
                                                    sx={{ fontSize: 14 }}
                                                    color='text.secondary'
                                                    gutterBottom
                                                >
                                                    SN:
                                                </Typography>
                                            </Grid>
                                            <Grid item xs={4} align='right'>
                                                <Typography
                                                    sx={{ fontSize: 14 }}
                                                    color='text.secondary'
                                                    gutterBottom
                                                >
                                                    {card.factory}
                                                </Typography>
                                            </Grid>
                                        </Grid>
                                        <Grid
                                            container
                                            spacing={2}
                                            align='left'
                                        >
                                            <Grid item xs={8}>
                                                <Typography
                                                    sx={{ fontSize: 14 }}
                                                    color='text.secondary'
                                                    gutterBottom
                                                >
                                                    OPERATOR:
                                                </Typography>
                                            </Grid>
                                            <Grid item xs={4} align='right'>
                                                <Typography
                                                    sx={{ fontSize: 14 }}
                                                    color='text.secondary'
                                                    gutterBottom
                                                >
                                                    {card.point}
                                                </Typography>
                                            </Grid>
                                        </Grid>
                                        <Divider />
                                        {state.activity.map(
                                            (product, index) => {
                                                return (
                                                    <div key={index}>
                                                        <Grid
                                                            container
                                                            spacing={2}
                                                            align='left'
                                                        >
                                                            <Grid item xs={6}>
                                                                <Typography
                                                                    sx={{
                                                                        fontSize: 14,
                                                                    }}
                                                                    color='text.secondary'
                                                                    gutterBottom
                                                                >
                                                                    {
                                                                        product.productName
                                                                    }
                                                                </Typography>
                                                            </Grid>
                                                            <Grid item xs={2}>
                                                                <Typography
                                                                    sx={{
                                                                        fontSize: 14,
                                                                    }}
                                                                    color='text.secondary'
                                                                    gutterBottom
                                                                >
                                                                    {
                                                                        product.productAmount
                                                                    }
                                                                </Typography>
                                                            </Grid>
                                                            <Grid item xs={1}>
                                                                <Typography
                                                                    sx={{
                                                                        fontSize: 14,
                                                                    }}
                                                                    color='text.secondary'
                                                                    gutterBottom
                                                                >
                                                                    X
                                                                </Typography>
                                                            </Grid>
                                                            <Grid item xs={2}>
                                                                <Typography
                                                                    sx={{
                                                                        fontSize: 14,
                                                                    }}
                                                                    color='text.secondary'
                                                                    gutterBottom
                                                                >
                                                                    {
                                                                        product.productPrice
                                                                    }
                                                                </Typography>
                                                            </Grid>
                                                        </Grid>
                                                        <Grid
                                                            container
                                                            spacing={2}
                                                            align='left'
                                                        >
                                                            <Grid
                                                                item
                                                                xs={6}
                                                            ></Grid>
                                                            <Grid
                                                                item
                                                                xs={2}
                                                            ></Grid>
                                                            <Grid
                                                                item
                                                                xs={1}
                                                            ></Grid>
                                                            <Grid item xs={2}>
                                                                <Typography
                                                                    sx={{
                                                                        fontSize: 14,
                                                                    }}
                                                                    color='text.secondary'
                                                                    gutterBottom
                                                                >
                                                                    {
                                                                        product.productCost
                                                                    }
                                                                </Typography>
                                                            </Grid>
                                                            <Grid item xs={1}>
                                                                <Typography
                                                                    sx={{
                                                                        fontSize: 14,
                                                                    }}
                                                                    color='text.secondary'
                                                                    gutterBottom
                                                                >
                                                                    {
                                                                        product
                                                                            .productTax
                                                                            .code
                                                                    }
                                                                </Typography>
                                                            </Grid>
                                                        </Grid>
                                                        {product?.productDiscountRate >
                                                            0 && (
                                                            <Grid
                                                                container
                                                                spacing={2}
                                                                align='left'
                                                            >
                                                                <Grid
                                                                    item
                                                                    xs={6}
                                                                >
                                                                    <Typography
                                                                        sx={{
                                                                            fontSize: 14,
                                                                        }}
                                                                        color='text.secondary'
                                                                        gutterBottom
                                                                    >
                                                                        {t(
                                                                            'discount',
                                                                        )}
                                                                    </Typography>
                                                                </Grid>
                                                                <Grid
                                                                    item
                                                                    xs={3}
                                                                >
                                                                    <Typography
                                                                        sx={{
                                                                            fontSize: 14,
                                                                        }}
                                                                        color='text.secondary'
                                                                        gutterBottom
                                                                    >
                                                                        {
                                                                            product?.productDiscountPercent
                                                                        }{' '}
                                                                        %
                                                                    </Typography>
                                                                </Grid>
                                                                <Grid
                                                                    item
                                                                    xs={3}
                                                                >
                                                                    <Typography
                                                                        sx={{
                                                                            fontSize: 14,
                                                                        }}
                                                                        color='text.secondary'
                                                                        gutterBottom
                                                                    >
                                                                        {
                                                                            product?.productDiscountRate
                                                                        }
                                                                    </Typography>
                                                                </Grid>
                                                            </Grid>
                                                        )}
                                                    </div>
                                                );
                                            },
                                        )}

                                        <Divider />

                                        <Grid
                                            container
                                            spacing={2}
                                            align='left'
                                        >
                                            <Grid item xs={9}>
                                                <Typography
                                                    sx={{
                                                        fontSize: 14,
                                                        fontWeight: 600,
                                                    }}
                                                    color='text.secondary'
                                                    gutterBottom
                                                >
                                                    TOTAL:
                                                </Typography>
                                            </Grid>
                                            <Grid item xs={2}>
                                                <Typography
                                                    sx={{
                                                        fontSize: 14,
                                                        fontWeight: 600,
                                                    }}
                                                    color='text.secondary'
                                                    gutterBottom
                                                >
                                                    {totalCost}
                                                </Typography>
                                            </Grid>
                                        </Grid>

                                        <Divider />

                                        {totalTax?.map((tax, index) => {
                                            const taxCode = tax.code;
                                            const totalVAT =
                                                sameVAT[taxCode]?.total.toFixed(
                                                    2,
                                                ) || 0;
                                            return (
                                                <Grid
                                                    container
                                                    spacing={2}
                                                    align='left'
                                                    key={index}
                                                >
                                                    <Grid item xs={9}>
                                                        <Typography
                                                            sx={{
                                                                fontSize: 14,
                                                            }}
                                                            color='text.secondary'
                                                            gutterBottom
                                                        >
                                                            TVA {tax.code}{' '}
                                                            {tax.code === 'F'
                                                                ? t('no_vat')
                                                                : `${tax.percent} %`}
                                                        </Typography>
                                                    </Grid>
                                                    <Grid item xs={2}>
                                                        <Typography
                                                            sx={{
                                                                fontSize: 14,
                                                            }}
                                                            color='text.secondary'
                                                            gutterBottom
                                                        >
                                                            {totalVAT}
                                                        </Typography>
                                                    </Grid>
                                                </Grid>
                                            );
                                        })}
                                        <Grid
                                            container
                                            spacing={2}
                                            align='left'
                                        >
                                            <Grid item xs={9}>
                                                <Typography
                                                    sx={{ fontSize: 14 }}
                                                    color='text.secondary'
                                                    gutterBottom
                                                >
                                                    TVA TOTAL:
                                                </Typography>
                                            </Grid>
                                            <Grid item xs={2}>
                                                <Typography
                                                    sx={{ fontSize: 14 }}
                                                    color='text.secondary'
                                                    gutterBottom
                                                >
                                                    {totalTaxRate}
                                                </Typography>
                                            </Grid>
                                        </Grid>

                                        <Divider />

                                        {CONSTANT_PAYMENT_METHODS.map(cpt => {
                                            if (cpt.type !== '1') {
                                                let cpt_type = cpt.type;
                                                let deposit = '';
                                                state.paymentsType.map(pt => {
                                                    if (pt.type === cpt_type) {
                                                        deposit = pt.deposit;
                                                    }
                                                });
                                                if (deposit !== '') {
                                                    return (
                                                        <Grid
                                                            container
                                                            spacing={2}
                                                            align='left'
                                                        >
                                                            <Grid item xs={9}>
                                                                <Typography
                                                                    sx={{
                                                                        fontSize: 14,
                                                                    }}
                                                                    color='text.secondary'
                                                                    gutterBottom
                                                                >
                                                                    {
                                                                        cpt.printName
                                                                    }
                                                                    :
                                                                </Typography>
                                                            </Grid>
                                                            <Grid item xs={2}>
                                                                <Typography
                                                                    sx={{
                                                                        fontSize: 14,
                                                                    }}
                                                                    color='text.secondary'
                                                                    gutterBottom
                                                                >
                                                                    {deposit}
                                                                </Typography>
                                                            </Grid>
                                                        </Grid>
                                                    );
                                                }
                                            }
                                        })}

                                        <Grid
                                            container
                                            spacing={2}
                                            align='left'
                                        >
                                            <Grid item xs={9}>
                                                <Typography
                                                    sx={{ fontSize: 14 }}
                                                    color='text.secondary'
                                                    gutterBottom
                                                >
                                                    SUBTOTAL:
                                                </Typography>
                                            </Grid>
                                            <Grid item xs={2}>
                                                <Typography
                                                    sx={{ fontSize: 14 }}
                                                    color='text.secondary'
                                                    gutterBottom
                                                >
                                                    {subtotal}
                                                </Typography>
                                            </Grid>
                                        </Grid>
                                        <Grid
                                            container
                                            spacing={2}
                                            align='left'
                                        >
                                            <Grid item xs={9}>
                                                <Typography
                                                    sx={{ fontSize: 14 }}
                                                    color='text.secondary'
                                                    gutterBottom
                                                >
                                                    NUMERAR:
                                                </Typography>
                                            </Grid>
                                            <Grid item xs={2}>
                                                <Typography
                                                    sx={{ fontSize: 14 }}
                                                    color='text.secondary'
                                                    gutterBottom
                                                >
                                                    {clientMoney}
                                                </Typography>
                                            </Grid>
                                        </Grid>
                                        <Grid
                                            container
                                            spacing={2}
                                            align='left'
                                        >
                                            <Grid item xs={9}>
                                                <Typography
                                                    sx={{ fontSize: 14 }}
                                                    color='text.secondary'
                                                    gutterBottom
                                                >
                                                    {isTicketPaymentType ? 'REST TMH' : 'REST'}:
                                                </Typography>
                                            </Grid>
                                            <Grid item xs={2}>
                                                <Typography
                                                    sx={{ fontSize: 14 }}
                                                    color='text.secondary'
                                                    gutterBottom
                                                >
                                                    {restMoney}
                                                </Typography>
                                            </Grid>
                                        </Grid>
                                        <Grid
                                            container
                                            spacing={2}
                                            align='left'
                                        >
                                            <Grid item xs={9}>
                                                <Typography
                                                    sx={{ fontSize: 14 }}
                                                    color='text.secondary'
                                                    gutterBottom
                                                >
                                                    Articole:
                                                </Typography>
                                            </Grid>
                                            <Grid item xs={2}>
                                                <Typography
                                                    sx={{ fontSize: 14 }}
                                                    color='text.secondary'
                                                    gutterBottom
                                                >
                                                    {state.activity.length.toFixed(
                                                        3,
                                                    )}
                                                </Typography>
                                            </Grid>
                                        </Grid>

                                        <Divider />
                                        <Grid
                                            container
                                            spacing={2}
                                            align='left'
                                        >
                                            <Grid item xs={8}>
                                                <Typography
                                                    sx={{ fontSize: 14 }}
                                                    color='text.secondary'
                                                    gutterBottom
                                                >
                                                    DATA: <CurrentDate />
                                                </Typography>
                                            </Grid>
                                            <Grid item xs={4}>
                                                <Typography
                                                    sx={{ fontSize: 14 }}
                                                    color='text.secondary'
                                                    gutterBottom
                                                    align='right'
                                                >
                                                    ORA: <CurrentTime />
                                                </Typography>
                                            </Grid>
                                        </Grid>

                                        {newReceipt?.soap_data?.receipt && (
                                            <Grid
                                                container
                                                spacing={2}
                                                align='left'
                                            >
                                                <Grid item xs={8}>
                                                    <Typography
                                                        sx={{ fontSize: 14 }}
                                                        color='text.secondary'
                                                        gutterBottom
                                                    >
                                                        BON FISCAL:
                                                    </Typography>
                                                </Grid>
                                                <Grid item xs={4}>
                                                    <Typography
                                                        sx={{ fontSize: 14 }}
                                                        color='text.secondary'
                                                        gutterBottom
                                                        align='right'
                                                    >
                                                        №:{' '}
                                                        {newReceipt.soap_data.receipt}
                                                    </Typography>
                                                </Grid>
                                            </Grid>
                                        )}

                                        <Grid
                                            container
                                            spacing={2}
                                            align='left'
                                        >
                                            <Grid item xs={6}>
                                                <Typography
                                                    sx={{ fontSize: 14 }}
                                                    color='text.secondary'
                                                    gutterBottom
                                                >
                                                    MEV ID:
                                                </Typography>
                                            </Grid>
                                            <Grid item xs={6}>
                                                <Typography
                                                    sx={{ fontSize: 14 }}
                                                    color='text.secondary'
                                                    gutterBottom
                                                    align='right'
                                                >
                                                    {mevID}
                                                </Typography>
                                            </Grid>
                                        </Grid>

                                        <Divider />

                                        {showQRBtn && (
                                            <>
                                                <Grid
                                                    container
                                                    spacing={2}
                                                    align='center'
                                                >
                                                    <Grid item xs={12}></Grid>
                                                    <Grid item xs={12}>
                                                        <div id='qrcode'>
                                                            {receiptUrl && (
                                                                <QRCode
                                                                    size={80}
                                                                    value={receiptUrl}
                                                                />
                                                            )}
                                                        </div>
                                                    </Grid>
                                                    <Grid item xs={12}></Grid>
                                                </Grid>
                                                <Divider />
                                            </>
                                        )}

                                        <Grid
                                            container
                                            spacing={2}
                                            align='center'
                                        >
                                            <Grid item xs={12}>
                                                <Typography
                                                    sx={{ fontSize: 14 }}
                                                    color='text.secondary'
                                                    gutterBottom
                                                >
                                                    *** BON FISCAL ***
                                                </Typography>
                                            </Grid>
                                        </Grid>
                                    </CardContent>
                                </Card>
                            )}

                            {!showReceipt && loadingMevId && (
                                <Card sx={{ width: 375 }} ref={componentRef}>
                                    <div align='center'>
                                        <CircularProgress sx={{ marginTop: 4, marginBottom: 4 }} />
                                    </div>
                                </Card>
                            )}
                        </Grid>
                    </Grid>
                </>
            )}

            <Snackbar
                open={openSuccess}
                autoHideDuration={6000}
                onClose={handleClose}
            >
                <Alert
                    onClose={handleClose}
                    severity='success'
                    sx={{ width: '100%' }}
                >
                    {t('receipt_created_msg')}
                </Alert>
            </Snackbar>
            <Snackbar
                open={error.status}
                autoHideDuration={6000}
                onClose={handleClose}
            >
                <Alert
                    onClose={handleClose}
                    severity='error'
                    sx={{ width: '100%' }}
                >
                    {error.msg}
                </Alert>
            </Snackbar>
            <Dialog
                open={openQRDialog}
                onClose={handleCloseQrDialog}
                aria-labelledby='alert-dialog-title'
                aria-describedby='alert-dialog-description'
            >
                <DialogTitle id='alert-dialog-title'>
                    {t('qr_code_description')}
                </DialogTitle>
                <DialogContent>
                    <div id='qrcode'>
                        <QRCode value={receiptUrl} />
                    </div>
                </DialogContent>
            </Dialog>
        </Box>
    );
}

function Products(props) {
    // const { t } = useTranslation();
const { t } = useTranslation(); // remove later
    let { taxesRates, products } = props;

    function addProduct() {
        products.push({
            productName: '',
            productAmount: '',
            productPrice: '',
            productDiscount: '',
            productDiscountType: '',
            productDiscountPercent: '',
            productDiscountRate: '',
            productVAT: 0,
            productTax: { code: '', percent: 0, rate: 0 },
            productCost: '',
        });
        props.setState(prev => {
            return {
                ...prev,
                activity: products,
            };
        });
    }

    function delProduct(index) {
        // delete cargo[index];
        products.splice(index, 1);
        props.setState(prev => {
            return {
                ...prev,
                activity: products,
            };
        });
    }
    const handleChangeAmount = amount => {
        const formattedAmount = parseFloat(amount).toFixed(3);
        let str = formattedAmount.replace('.', '');
        let result = '';
        if (str.length <= 3) {
            let pad = '0.000';
            result = pad.substring(0, pad.length - str.length) + str;
        }
        if (str.length > 3) {
            let x_res =
                parseInt(str.substring(0, str.length - 3)).toString() +
                '.' +
                str.substring(str.length - 3, str.length);
            result = x_res;
        }
        return result;
    };

    const handleChangePrice = price => {
        let str = price.replace('.', '');
        let result = '';
        if (str.length <= 2) {
            let pad = '0.00';
            result = pad.substring(0, pad.length - str.length) + str;
        }
        if (str.length > 2) {
            let x_res =
                parseInt(str.substring(0, str.length - 2)).toString() +
                '.' +
                str.substring(str.length - 2, str.length);
            result = x_res;
        }
        return result;
    };

    const handleChangeName = (oldName, newName) => {
        var ru = /[а-яё]+/i.test(newName);
        if (ru) {
            alert('Use only latin litteres!');
            return oldName;
        }
        return newName;
    };

    const handleChangeDiscount = value => {
        let str = value.replace('.', '');
        let result = '';

        if (str.length <= 2) {
            let pad = '0.00';
            result = pad.substring(0, pad.length - str.length) + str;
        }
        if (str.length > 2) {
            let x_res =
                parseInt(str.substring(0, str.length - 2)).toString() +
                '.' +
                str.substring(str.length - 2, str.length);
            result = x_res;
        }

        return result;
    };

    const calcDiscountRate = (amount, price, percent) => {
        return parseFloat((amount * price * percent) / 100).toFixed(2);
    };
    const calcDiscountPercent = (amount, price, value) => {
        return parseFloat((value * 100) / (amount * price)).toFixed(2);
    };

    function handleChange(event, index, amountBlur = false) {
        let changebleProduct = products[index];
        const regexExpression = /^[0-9]*(\.){0,1}[0-9]*$/g;
        const name = event.target.name;
        const value = event.target.value;
        if (name === 'productAmount' && value.match(regexExpression)) {
            if (amountBlur && value) {
                changebleProduct[name] = handleChangeAmount(value);
            } else {
                changebleProduct[name] = value;
            }
        }
        if (name === 'productPrice' && value.match(regexExpression)) {
            changebleProduct[name] = handleChangePrice(value);
        }
        if (name === 'productName') {
            changebleProduct[name] = handleChangeName(
                changebleProduct[name],
                value,
            );
        }

        if (name === 'productTax') {
            changebleProduct[name] = value;
        }

        if (name === 'productDiscountType') {
            changebleProduct[name] = value;
        }

        if (name === 'productDiscountPercent') {
            changebleProduct[name] = handleChangeDiscount(value);
            changebleProduct['productDiscountRate'] = calcDiscountRate(
                changebleProduct.productAmount,
                changebleProduct.productPrice,
                handleChangeDiscount(value),
            );
        }
        if (name === 'productDiscountRate') {
            changebleProduct[name] = handleChangeDiscount(value);
            changebleProduct['productDiscountPercent'] = calcDiscountPercent(
                changebleProduct.productAmount,
                changebleProduct.productPrice,
                handleChangeDiscount(value),
            );
        }

        if (
            changebleProduct.productAmount !== '' &&
            changebleProduct.productPrice !== ''
        ) {
            let amount = parseFloat(changebleProduct.productAmount);
            let price = parseFloat(changebleProduct.productPrice);
            let discount_type = changebleProduct.productDiscountType;
            let discount_percent =
                parseFloat(changebleProduct.productDiscountPercent) || 0;
            let discount_rate =
                parseFloat(changebleProduct.productDiscountRate) || 0;
            let discount = 0;
            if (discount_type === 'percent') {
                discount = calcDiscountRate(amount, price, discount_percent);
                changebleProduct['productDiscountRate'] = calcDiscountRate(
                    amount,
                    price,
                    discount_percent,
                );
            } else {
                discount = discount_rate;
            }
            let cost = amount * price - discount;
            changebleProduct.productCost = cost.toFixed(2);
        }

        products[index] = changebleProduct;
        props.setState(prev => {
            return {
                ...prev,
                activity: products,
            };
        });
    }

    return (
        <div>
            {products.map((product, index) => {
                return (
                    <div key={`product_${index}`}>
                        <Grid container spacing={2} align='left'>
                            <Grid item xs={2}>
                                <FormControl
                                    fullWidth
                                    sx={{ m: 1 }}
                                    variant='standard'
                                >
                                    <InputLabel htmlFor='component-simple'>
                                        {t('product_name')}
                                    </InputLabel>
                                    <Input
                                        id='component-simple'
                                        value={product.productName}
                                        onChange={event =>
                                            handleChange(event, index)
                                        }
                                        name='productName'
                                    />
                                </FormControl>
                            </Grid>
                            <Grid item xs={1}>
                                <FormControl
                                    fullWidth
                                    sx={{ m: 1 }}
                                    variant='standard'
                                >
                                    <InputLabel htmlFor='component-simple'>
                                        {t('product_amount')}
                                    </InputLabel>
                                    <Input
                                        id='component-simple'
                                        value={product.productAmount}
                                        onBlur={event =>
                                            handleChange(event, index, true)
                                        }
                                        onChange={event =>
                                            handleChange(event, index)
                                        }
                                        name='productAmount'
                                    />
                                </FormControl>
                            </Grid>
                            <Grid item xs={2}>
                                <FormControl
                                    fullWidth
                                    sx={{ m: 1 }}
                                    variant='standard'
                                >
                                    <InputLabel htmlFor='component-simple'>
                                        {t('product_price')}
                                    </InputLabel>
                                    <Input
                                        id='component-simple'
                                        value={product.productPrice}
                                        onChange={event =>
                                            handleChange(event, index)
                                        }
                                        name='productPrice'
                                    />
                                </FormControl>
                            </Grid>
                            <Grid item xs={1}>
                                <TextField
                                    select
                                    label={t('discount_type')}
                                    name='productDiscountType'
                                    value={product.productDiscountType}
                                    onChange={event =>
                                        handleChange(event, index)
                                    }
                                    fullWidth
                                    sx={{ m: 1 }}
                                    variant='standard'
                                >
                                    <MenuItem
                                        key='precent-type'
                                        value='percent'
                                    >
                                        %
                                    </MenuItem>
                                    <MenuItem key='fix-type' value='fix'>
                                        lei
                                    </MenuItem>
                                </TextField>
                            </Grid>
                            <Grid item xs={2}>
                                <FormControl
                                    fullWidth
                                    sx={{ m: 1 }}
                                    variant='standard'
                                >
                                    <InputLabel
                                        htmlFor='component-discount'
                                        shrink={
                                            product.productDiscountType
                                                ? true
                                                : false
                                        }
                                        error={
                                            parseFloat(product.productCost) <= 0
                                        }
                                    >
                                        {t('discount_amount')}
                                    </InputLabel>
                                    <Input
                                        id='component-discount'
                                        value={
                                            product.productDiscountType ===
                                            'percent'
                                                ? product.productDiscountPercent
                                                : product.productDiscountRate
                                        }
                                        onChange={event =>
                                            handleChange(event, index)
                                        }
                                        name={
                                            product.productDiscountType ===
                                            'percent'
                                                ? 'productDiscountPercent'
                                                : 'productDiscountRate'
                                        }
                                        disabled={
                                            product.productDiscountType === ''
                                        }
                                        error={
                                            parseFloat(product.productCost) <= 0
                                        }
                                    />
                                </FormControl>
                            </Grid>

                            <Grid item xs={2}>
                                <TextField
                                    id='standard-select-currency'
                                    select
                                    label={t('tax')}
                                    name='productTax'
                                    value={product.productTax}
                                    onChange={event =>
                                        handleChange(event, index)
                                    }
                                    helperText={t('select_tax_rate')}
                                    fullWidth
                                    sx={{ m: 1 }}
                                    variant='standard'
                                >
                                    {taxesRates?.map((option, index) => (
                                        <MenuItem
                                            key={`tax_${index}`}
                                            value={option}
                                        >
                                            {`${option.code} ${option.percent}%`}
                                        </MenuItem>
                                    ))}
                                </TextField>
                            </Grid>

                            <Grid item xs={1}>
                                <FormControl
                                    fullWidth
                                    sx={{ m: 1 }}
                                    variant='standard'
                                >
                                    <InputLabel htmlFor='component-simple'>
                                        {t('cost')}
                                    </InputLabel>
                                    <Input
                                        id='component-simple'
                                        value={product.productCost}
                                        name='cost'
                                    />
                                </FormControl>
                            </Grid>
                            <Grid item xs={1} align='center'>
                                <IconButton
                                    aria-label='account of current user'
                                    aria-controls='menu-appbar'
                                    aria-haspopup='true'
                                    size='small'
                                    sx={{ marginTop: 3 }}
                                    onClick={() => delProduct(index)}
                                    style={{
                                        visibility:
                                            props.products.length > 1
                                                ? 'visible'
                                                : 'hidden',
                                    }}
                                >
                                    <DeleteForeverOutlinedIcon />
                                </IconButton>
                            </Grid>
                        </Grid>
                    </div>
                );
            })}
            <Button
                variant='outlined'
                startIcon={<AddIcon />}
                onClick={() => addProduct()}
            >
                {t('add_product')}
            </Button>
        </div>
    );
}

function CurrentDate() {
    let today = new Date();
    let pad = '00';
    let month = today.getMonth() + 1;
    month = pad.substring(0, pad.length - month.toString.length) + month;
    let currentDate =
        parseInt(today.getDate()) + '.' + month + '.' + today.getFullYear();
    return <>{currentDate}</>;
}

function CurrentTime() {
    const now = new Date();
    const timeString = now.toLocaleTimeString('ru-MD', { hour12: false });
    return <>{timeString}</>;
}

function PaymentsTypes(props) {
    const { t } = useTranslation();
    let paymentsType = props.paymentsType;
    let restMoney = props.restMoney;

    function addPaymentsMethod() {
        let allowed = true;
        paymentsType.forEach(pt => {
            if (pt.type === '') {
                allowed = false;
            }
        });
        if (allowed) {
            paymentsType.push({
                type: '',
                deposit: '0.00',
            });
            props.setState(prev => {
                return {
                    ...prev,
                    paymentsType: paymentsType,
                };
            });
        }
    }

    function handleChangePaymentMethodType(event, index) {
        let changed = {
            type: event.target.value,
            deposit: '0.00',
        };
        let allowed = true;
        paymentsType.forEach(pt => {
            if (pt.type === event.target.value) {
                allowed = false;
            }
        });
        if (allowed) {
            paymentsType[index] = changed;
            props.setState(prev => {
                return {
                    ...prev,
                    paymentsType: paymentsType,
                };
            });
        } else {
            paymentsType[index].deposit = 0;
            props.setState(prev => {
                return {
                    ...prev,
                    paymentsType: paymentsType,
                };
            });
        }
    }

    function delPaymentsMethod(index) {
        paymentsType.splice(index, 1);
        props.setState(prev => {
            return {
                ...prev,
                paymentsType: paymentsType,
            };
        });
    }

    function validate(s) {
        var rgx = /^[0-9]*\.?[0-9]*$/;
        return s.match(rgx);
    }

    const handleChangePaymentMoney = (event, index) => {
        if (paymentsType[index].type !== '') {
            if (validate(event.target.value)) {
                let str = event.target.value.replace('.', '');
                let result = '';

                if (str.length <= 2) {
                    let pad = '0.00';
                    result = pad.substring(0, pad.length - str.length) + str;
                }
                if (str.length > 2) {
                    let x_res =
                        parseInt(str.substring(0, str.length - 2)).toString() +
                        '.' +
                        str.substring(str.length - 2, str.length);
                    result = x_res;
                }

                paymentsType[index].deposit = result;
                props.setState(prev => {
                    return {
                        ...prev,
                        paymentsType: paymentsType,
                    };
                });
            }
        }
    };

    return (
        <div>
            {paymentsType.map((paymentsType, index) => {
                return (
                    <div key={paymentsType.type + index}>
                        <Grid
                            container
                            spacing={2}
                            sx={{ marginBottom: 1 }}
                            align='left'
                        >
                            <Grid item xs={5}>
                                <FormControl fullWidth>
                                    <InputLabel id='demo-simple-select-label'>
                                        {t('payment_type')}
                                    </InputLabel>
                                    <Select
                                        labelId='demo-simple-select-label'
                                        id='demo-simple-select'
                                        value={paymentsType.type}
                                        label='paymentsType'
                                        onChange={event =>
                                            handleChangePaymentMethodType(
                                                event,
                                                index,
                                            )
                                        }
                                        name='paymentsType'
                                    >
                                        {CONSTANT_PAYMENT_METHODS.map(
                                            (paymentMethod, index) => {
                                                return (
                                                    <MenuItem
                                                        value={
                                                            paymentMethod.type
                                                        }
                                                        key={index}
                                                    >
                                                        {t([
                                                            paymentMethod.methodName,
                                                        ])}
                                                    </MenuItem>
                                                );
                                            },
                                        )}
                                    </Select>
                                </FormControl>
                            </Grid>
                            <Grid item xs={3}>
                                <FormControl variant='standard'>
                                    <InputLabel htmlFor='component-simple'>
                                        {t('amount')}
                                    </InputLabel>
                                    <Input
                                        id='component-simple'
                                        value={paymentsType.deposit}
                                        onChange={event =>
                                            handleChangePaymentMoney(
                                                event,
                                                index,
                                            )
                                        }
                                        name='deposit'
                                    />
                                </FormControl>
                            </Grid>
                            <Grid item xs={3}>
                                {paymentsType.type === PaymentTypes.CASH && (
                                    <FormControl variant='standard'>
                                        <InputLabel htmlFor='component-simple'>
                                            {t('change')}
                                        </InputLabel>
                                        <Input
                                            id='component-simple'
                                            value={restMoney}
                                            name='changeClientMoney'
                                        />
                                    </FormControl>
                                )}
                            </Grid>
                            <Grid item xs={1}>
                                <IconButton
                                    aria-label='account of current user'
                                    aria-controls='menu-appbar'
                                    aria-haspopup='true'
                                    size='small'
                                    sx={{ marginTop: 3 }}
                                    onClick={() => delPaymentsMethod(index)}
                                    style={{
                                        visibility:
                                            props.paymentsType.length > 1
                                                ? 'visible'
                                                : 'hidden',
                                    }}
                                >
                                    <DeleteForeverOutlinedIcon />
                                </IconButton>
                            </Grid>
                        </Grid>
                    </div>
                );
            })}
            <br />
            <br />
            <Button variant='outlined' onClick={addPaymentsMethod}>
                {t('add_payment_method_btn')}
            </Button>
        </div>
    );
}

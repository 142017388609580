
import { useState } from 'react';

import { getOrganizationByCashRegister } from '../../../../services/requests/ApiRequests';
import { name } from 'dayjs/locale/ru';

const useCashRegisterForm = ({ onSubmit }) => {
  const [formData, setFormData] = useState({
    // idnx: "1004600001907",
    // name: "Simpals S.R.L.",
    // model: "eKassa",
    // address: "mun. Chisinau, str. Calea orheiului, 28/1",
    // factory: "71b25bccc6",
    // number: "AB1110001172",
    // key: "oe8Wk5OiUEFIQVYtL3SL",
    // point: "1",

    idnx: "",
    name: "",
    model: "",
    address: "",
    factory: "",
    number: "",
    key: "",
    point: "",
  });

  const [error, setError] = useState({ status: null, msg: "" });
  const [openSuccess, setOpenSuccess] = useState(false);

  // Handle text input changes
  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  // Reset all fields to either empty or initialValues
  const resetForm = () => {
    setFormData({
      idnx: "",
      name: "",
      model: "",
      address: "",
      factory: "",
      number: "",
      key: "",
      domain: "",
      point: "",
    });
  };

  const handleSubmit = () => {
    return getOrganizationByCashRegister(formData).then((response) => {
      console.log('response', response);
      onSubmit({
        organization: response.data,
        formData,
      });
      // setOrganization(response.data);
      setOpenSuccess(true);
    }).catch((error) => {
      const data = error?.response?.data || {};
      const msg = data.detail?.[0]?.msg || data.error  || 'Error';
      const status = error?.response?.status || null;
      setError({ msg, status });
      setOpenSuccess(false);
    });
  };

  const handleClose = (event, reason) => {
    setError({ status: null, msg: "" });
    setOpenSuccess(false);
  };


  return {
    formData,
    handleChange,
    resetForm,
    setFormData, // in case you need to manipulate the entire state at once
    handleSubmit,
    handleClose,
    error,
    openSuccess,
  };
};

export default useCashRegisterForm;
import React from 'react';
import { useTranslation } from 'react-i18next';

import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import TextField from '@mui/material/TextField';
import MenuItem from '@mui/material/MenuItem';
import Grid from '@mui/material/Grid';
import FormControlLabel from '@mui/material/FormControlLabel';
import Radio from '@mui/material/Radio';
import FormControl from '@mui/material/FormControl';
import Input from '@mui/material/Input';
import InputLabel from '@mui/material/InputLabel';
import Button from '@mui/material/Button';
import Card from '@mui/material/Card';
import Divider from '@mui/material/Divider';
import CardContent from '@mui/material/CardContent';
import Alert from '@mui/material/Alert';
import Snackbar from '@mui/material/Snackbar';

import { createNewServiceReceipt } from '../../services/requests/CreateNewReceipt';
import CashRegisterForm from './CashRegisterForm';

export default function NewServiceReceipt(props) {
    const { t } = useTranslation();
    const [organization, setOrganization] = React.useState({});
    const [userName, setUserName] = React.useState('');
    // const [userapiKey, setUserApiKey] = React.useState('');
    const [openSuccess, setOpenSuccess] = React.useState(false);
    const [error, setError] = React.useState({
        status: false,
        msg: 'Error',
    });

    // React.useEffect(() => {
        // setOrganization(props.organizationAndUser.organization);
        // setUserName(props.organizationAndUser.user);
    // }, [props]);
    const [selectedCashRegister, setSelectedCashRegister] = React.useState({
        address: '',
        number: '',
        factory: '',
        apiKey: '',
        model: '',
        id: '',
    });
    const [card, setCard] = React.useState({});
    // type 1 - input; 2 - output
    const [type, setType] = React.useState('');
    // function handleChangeCash(event) {
    //     const selected = props?.organizationAndUser?.userCashRegisters?.find(
    //         item => item.cashRegisterId === event.target?.value,
    //     );

    //     if(!selected) return;

    //     setSelectedCashRegister({
    //         address: selected.cashRegister.address,
    //         number: selected.cashRegister.number,
    //         factory: selected.cashRegister.factory,
    //         model: selected.cashRegister.model,
    //         apiKey: selected.apiKey,
    //         id: selected.cashRegisterId,
    //     });
    // }
    function handleChangeType(event) {
        // console.log("Type: ", event.target.value)
        setType(event.target.value);
    }

    const [summ, setSumm] = React.useState('');

    const handleChangePrice = event => {
        let price = event.target.value;
        let str = price.replace('.', '');
        let result = '';
        if (str.length <= 2) {
            let pad = '0.00';
            result = pad.substring(0, pad.length - str.length) + str;
        }
        if (str.length > 2) {
            let x_res =
                parseInt(str.substring(0, str.length - 2)).toString() +
                '.' +
                str.substring(str.length - 2, str.length);
            result = x_res;
        }
        // console.log("result: ", result)
        setSumm(result);
    };

    const onCashRegisterFormSubmit = data => {
        setOrganization(data.organization);
        setCard(data.formData);
    };

    const [showReceipt, setShowReceipt] = React.useState(false);

    function createReceipt() {
        const param = {
            soap_data: {
                card,
                data: {
                    type: parseInt(type),
                    name: 'MDL',
                    total: summ,
                },
            }
        };
        createNewServiceReceipt(param)
            .then(data => {
                if (data.data.soap_data) {
                    setOpenSuccess(true);
                } else {
                    setError({
                        status: true,
                        msg: 'There are errors while receipt generation',
                    });
                }
            })
            .catch(error => {
                // console.log("Error: ", error);
                setError({
                    status: true,
                    msg: 'There are errors while receipt generation',
                });
            });
    }

    const [vererification, setVererification] = React.useState(false);

    React.useEffect(() => {
        if (card && card.address !== '' && type !== '' && summ !== '') {
            if (parseFloat(summ) > 0) {
                setVererification(true);
                // console.log('vererification true');
            } else {
                setVererification(false);
            }
        }

        setShowReceipt(false);
    }, [card, type, summ]);

    const handleClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }

        setOpenSuccess(false);
        setError({
            status: false,
            msg: 'Error',
        });
    };
    // console.log("showReceipt: ", showReceipt)
    // console.log("vererification: ",vererification)
    // console.log("selectedCashRegister: ", selectedCashRegister)
    const isCashRegisterAvailable = !!Object.keys(card).length;
    return (
        <Box
            sx={{ flexGrow: 1, marginTop: 3, marginLeft: 10, marginBottom: 10 }}
        >
            <Typography
                sx={{ fontSize: 24, marginTop: 6 }}
                color='pimary'
                gutterBottom
            >
                {t('new_service_receipt')}
            </Typography>
            <Grid container spacing={4} align='left'>
                <Grid item xs={6}>
                    <CashRegisterForm onSubmit={onCashRegisterFormSubmit} />
                    {isCashRegisterAvailable && (
                        <>
                            <Grid container spacing={2} align='left'>
                                <Grid item xs={11}>
                                    <FormControlLabel
                                        value={t('cash_in')}
                                        control={
                                            <Radio
                                                checked={type === '1'}
                                                onChange={handleChangeType}
                                                value='1'
                                                name='radio-button-input'
                                                inputProps={{ 'aria-label': 'input' }}
                                            />
                                        }
                                        label={t('cash_in')}
                                    />
                                    <FormControlLabel
                                        value={t('cash_collection')}
                                        control={
                                            <Radio
                                                checked={type === '2'}
                                                onChange={handleChangeType}
                                                value='2'
                                                name='radio-button-output'
                                                inputProps={{ 'aria-label': 'output' }}
                                            />
                                        }
                                        label={t('cash_collection')}
                                    />
                                </Grid>
                            </Grid>
                            <Grid container spacing={2} align='left'>
                                <Grid item xs={12}>
                                    <FormControl
                                        fullWidth
                                        sx={{ m: 1 }}
                                        variant='standard'
                                    >
                                        <InputLabel htmlFor='component-simple'>
                                            Product price
                                        </InputLabel>
                                        <Input
                                            id='component-simple-summ'
                                            sx={{ align: 'right' }}
                                            value={summ}
                                            onChange={event => handleChangePrice(event)}
                                            name='summ'
                                        />
                                    </FormControl>
                                </Grid>
                            </Grid>
                            <Grid container spacing={2} align='left'>
                                <Grid item xs={6}>
                                    <Button
                                        variant='outlined'
                                        sx={{ width: '100%' }}
                                        disabled={!vererification}
                                        onClick={() => {
                                            setShowReceipt(true);
                                        }}
                                    >
                                        {t('show')}
                                    </Button>
                                </Grid>
                                <Grid item xs={6}>
                                    <Button
                                        variant='outlined'
                                        sx={{ width: '100%' }}
                                        disabled={!vererification || !showReceipt}
                                        onClick={() => {
                                            createReceipt();
                                        }}
                                    >
                                        {t('creat_send')}
                                    </Button>
                                </Grid>
                            </Grid>
                        </>
                    )}
                </Grid>
                <Grid
                    item
                    xs={6}
                    sx={{ visibility: showReceipt ? 'visible' : 'hidden' }}
                >
                    <Card sx={{ width: 375 }}>
                        <CardContent>
                            <Typography
                                variant='h5'
                                component='h5'
                                color='text.secondary'
                                align='center'
                            >
                                {organization.name}
                            </Typography>
                            <Typography
                                sx={{ fontSize: 14 }}
                                color='text.secondary'
                                align='center'
                                gutterBottom
                            >
                                COD FISCAL: {organization.idno}
                            </Typography>
                            <Typography
                                sx={{ fontSize: 14 }}
                                color='text.secondary'
                                align='center'
                                gutterBottom
                            >
                                {card.address}
                            </Typography>
                            <Grid container spacing={2} align='left'>
                                <Grid item xs={8}>
                                    <Typography
                                        sx={{ fontSize: 14 }}
                                        color='text.secondary'
                                        gutterBottom
                                    >
                                        NUMARUL DE INREGISTRARE:
                                    </Typography>
                                </Grid>
                                <Grid item xs={4} align='right'>
                                    <Typography
                                        sx={{ fontSize: 14 }}
                                        color='text.secondary'
                                        gutterBottom
                                    >
                                        {card.number}
                                    </Typography>
                                </Grid>
                            </Grid>

                            <Grid container spacing={2} align='left'>
                                <Grid item xs={8}>
                                    <Typography
                                        sx={{ fontSize: 14 }}
                                        color='text.secondary'
                                        gutterBottom
                                    >
                                        SN:
                                    </Typography>
                                </Grid>
                                <Grid item xs={4} align='right'>
                                    <Typography
                                        sx={{ fontSize: 14 }}
                                        color='text.secondary'
                                        gutterBottom
                                    >
                                        {card.factory}
                                    </Typography>
                                </Grid>
                            </Grid>
                            <Divider />
                            <Grid container spacing={2}>
                                <Grid item xs={8} align='left'>
                                    <Typography
                                        sx={{ fontSize: 14, fontWeight: 600 }}
                                        color='text.secondary'
                                        gutterBottom
                                    >
                                        {type === '1' && <>INTRODUCED</>}
                                        {type === '2' && <>ISSUED</>}
                                    </Typography>
                                </Grid>
                                <Grid item xs={4} align='right'>
                                    <Typography
                                        sx={{ fontSize: 14, fontWeight: 600 }}
                                        align='right'
                                        color='text.secondary'
                                        gutterBottom
                                    >
                                        {summ}
                                    </Typography>
                                </Grid>
                            </Grid>
                            <Divider />
                            <Grid container spacing={2} align='left'>
                                <Grid item xs={8}>
                                    <Typography
                                        sx={{ fontSize: 14 }}
                                        color='text.secondary'
                                        gutterBottom
                                    >
                                        DATA: <CurrentDate />
                                    </Typography>
                                </Grid>
                                <Grid item xs={4} align='right'>
                                    <Typography
                                        sx={{ fontSize: 14 }}
                                        color='text.secondary'
                                        gutterBottom
                                    >
                                        ORA: <CurrentTime />
                                    </Typography>
                                </Grid>
                            </Grid>

                            <Divider />
                        </CardContent>
                    </Card>
                </Grid>
            </Grid>
            <Snackbar
                open={openSuccess}
                autoHideDuration={6000}
                onClose={handleClose}
            >
                <Alert
                    onClose={handleClose}
                    severity='success'
                    sx={{ width: '100%' }}
                >
                    {t('receipt_created_msg')}
                </Alert>
            </Snackbar>
            <Snackbar
                open={error.status}
                autoHideDuration={6000}
                onClose={handleClose}
            >
                <Alert
                    onClose={handleClose}
                    severity='error'
                    sx={{ width: '100%' }}
                >
                    {error.msg}
                </Alert>
            </Snackbar>
        </Box>
    );
}

function CurrentDate() {
    let today = new Date();
    let pad = '00';
    let month = today.getMonth() + 1;
    month = pad.substring(0, pad.length - month.toString.length) + month;
    let currentDate =
        parseInt(today.getDate()) + '.' + month + '.' + today.getFullYear();
    return <>{currentDate}</>;
}

function CurrentTime() {
    let today = new Date();
    let time =
        today.getHours() + ':' + today.getMinutes() + ':' + today.getSeconds();
    return <>{time}</>;
}

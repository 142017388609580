export const devMevAuth =
    '5057c266147fac1e875a64d68d795fc4644796282d07648fc10e815dbecb1efbadce64472d9cc8d023a9db64c817b6743a6ccb20e0e72ca867463d6867fe8c31732cdc8a03083ca438be88f3148c3617b59bdd2b5baedc6ea388052279b468523dacc5344f45d7ef08545b3ffee9818c8bef3f3d3a114276191f54f54127794687771d7529c13f481b75e54f9824c09f8101821ddde1cdbadc88c4799cc16e4d7ce6e9cd5ace8b63aa23f2809d95e59827182036d34ba0ec211b2499da99561d82c126d2d035a06c96e9ba2d50fd364f';
export const queryLimit = 20;

export const ROLE = {
    accountant: 'ORGANIZATION_ACCOUNTANT',
    director: 'ORGANIZATION_DIRECTOR',
    operator: 'ORGANIZATION_OPERATOR',
    cashier: 'ORGANIZATION_CASHIER',
};

export const TAX_CODES = [
    'A',
    'B',
    'C',
    'D',
    'E',
    'F',
    'G',
    'H',
    'I',
    'J',
    'K',
    'L',
    'M',
    'N',
    'O',
    'P',
    'Q',
    'R',
    'S',
    'T',
    'U',
    'V',
    'W',
    'X',
    'Y',
    'Z',
];

export const RECIEPT_TYPES = [
    'synced',
    'send_to_sync',
    'received',
    'duplicate',
    'error_on_sync',
    'generated',
];

export const PAYMENT_TYPES = {
    NUMERAR: '1',
    CARD: '2',
    VAUCHER: '3.1',
    CEC: '3.2',
    TICHET: '3.3',
    TME: '5',
    ABONAMENT: '6',
    ALT_TIP: '7',
};

export const CONSTANT_PAYMENT_METHODS = [
    {
        type: '1',
        methodName: 'cash',
        printName: 'NUMERAR',
    },

    {
        type: '2',
        methodName: 'credit_card',
        printName: 'CARD',
    },
    {
        type: '3.1',
        methodName: 'payment_methods_voucher',
        printName: 'VAUCHER',
    },
    {
        type: '3.2',
        methodName: 'payment_methods_certificate',
        printName: 'CEC',
    },
    {
        type: '3.3',
        methodName: 'payment_methods_ticket',
        printName: 'TICHET',
    },
    {
        type: '5',
        methodName: 'payment_methods_food_voucher',
        printName: 'TME',
    },
    {
        type: '6',
        methodName: 'payment_methods_subscription',
        printName: 'ABONAMENT',
    },
    {
        type: '7',
        methodName: 'payment_methods_other_payment_instrument',
        printName: 'ALT_TIP',
    },
];

export const ACCESS_ID_COOKIE = 'access-id';
export const ROLE_COOKIE = 'role';

export const QR_CODE_LINK = 'https://mev-pre.sfs.md/receipt-verifier/';

export const REPORT_TYPES = {
    Z: 1,
    X: 2,
};

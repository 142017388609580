export function valueToFixed(value, limit) {
    let str = value.replace('.', '');
    let result = '';
    if (str.length <= 2) {
        let pad = '0.00';
        result = pad.substring(0, pad.length - str.length) + str;
    }
    if (str.length > 2) {
        let x_res =
            parseInt(str.substring(0, str.length - 2)).toString() +
            '.' +
            str.substring(str.length - 2, str.length);
        result = x_res;
    }
    return (limit && result > limit)  ? limit.toFixed(2) : result;
}

/* eslint-disable react-hooks/exhaustive-deps */
import React, { useCallback, useState } from 'react';
import { DatePicker, DatesProvider } from '@mantine/dates';
import 'dayjs/locale/ru';
import CalendarIcon from './components/CalendarIcon';
import styled from '@emotion/styled';
import dayjs from 'dayjs';
import { CloseButton, Popover, TextInput } from '@mantine/core';
import { IconButton } from '@mui/material';

const formatDate = ({ date, format }) => {
    if (!date) return '';
    const formattedDate = dayjs(date).format(format);

    return formattedDate;
};

const DatePickerRange = ({ isCashSelected, setSortingDates }) => {
    const [pickerValues, setPickerValues] = useState({
        values: [null, null],
        string: '',
    });
    const [popoverOpened, setPopoverOpened] = useState(false);

    const handleDatePick = useCallback(values => {
        const isPicked = values.every(value => value !== null);
        const startDate = formatDate({ date: values[0], format: 'DD.MM.YY' });
        const endDate = formatDate({ date: values[1], format: 'DD.MM.YY' });
        const dateString = `${startDate} - ${endDate}`;
        setPickerValues({
            values,
            string: dateString,
        });

        if (!isPicked) return;
        setSortingDates({
            dateFrom: formatDate({ date: values[0], format: 'YYYY-MM-DD' }),
            dateTo: formatDate({ date: values[1], format: 'YYYY-MM-DD' }),
        });
        setPopoverOpened(false);
    }, []);

    const handleReset = useCallback(() => {
        setPickerValues({
            values: [null, null],
            string: '',
        });
        setSortingDates({
            dateFrom: null,
            dateTo: null,
        });
    }, []);

    const handleRangeSelection = rangeType => () => {
        const today = dayjs();
        let startDate, endDate;

        switch (rangeType) {
            case 'day':
                startDate = today;
                endDate = today;
                break;
            case 'week':
                startDate = today.startOf('week');
                endDate = today.endOf('week');
                break;
            case 'month':
                startDate = today.startOf('month');
                endDate = today.endOf('month');
                break;
            default:
                break;
        }

        handleDatePick([startDate.toDate(), endDate.toDate()]);
    };

    if (!isCashSelected) return null;
    return (
        <StyledPopover
            opened={popoverOpened}
            position='bottom-start'
            closeOnClickOutside={true}
            onClose={() => setPopoverOpened(false)}
        >
            <Popover.Target>
                <TextInput
                    value={pickerValues.string}
                    clearable="true"
                    size='md'
                    maw={400}
                    readOnly
                    onClick={() => setPopoverOpened(open => !open)}
                    style={{
                        marginBottom: 16,
                        marginTop: 8,
                    }}
                    placeholder='Укажите период'
                    icon={<CalendarIcon />}
                    rightSection={
                        pickerValues.string && (
                            <CloseButton onClick={handleReset} />
                        )
                    }
                />
            </Popover.Target>
            <Popover.Dropdown>
                <PopoverWrapper>
                    <Ranges>
                        <RangeTitle>Период</RangeTitle>
                        <Range onClick={handleRangeSelection('day')}>
                            День
                        </Range>
                        <Range onClick={handleRangeSelection('week')}>
                            Неделя
                        </Range>
                        <Range onClick={handleRangeSelection('month')}>
                            Месяц
                        </Range>
                    </Ranges>
                    <DatesProvider settings={{ locale: 'ru' }}>
                        <StyledPicker
                            value={pickerValues.values}
                            type='range'
                            size='md'
                            defaultValue={new Date()}
                            allowSingleDateInRange
                            onChange={handleDatePick}
                        />
                    </DatesProvider>
                </PopoverWrapper>
            </Popover.Dropdown>
        </StyledPopover>
    );
};

export default DatePickerRange;

const StyledPicker = styled(DatePicker)``;

const StyledPopover = styled(Popover)`
    .mantine-Popover-dropdown {
        box-shadow: 0px 2px 6px rgba(15, 113, 189, 0.3);
        border-radius: 4px;
    }
`;

const PopoverWrapper = styled.div`
    display: flex;
`;

const Ranges = styled.div`
    display: flex;
    flex-direction: column;
    margin-right: 16px;
`;

const RangeTitle = styled.div`
    color: #97989e;
    margin: 16px 0;
`;

const Range = styled.div`
    color: #313238;
    margin-bottom: 16px;
    cursor: pointer;
`;

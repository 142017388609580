/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';

import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import TextField from '@mui/material/TextField';
import MenuItem from '@mui/material/MenuItem';
import CircularProgress from '@mui/material/CircularProgress';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { Pagination } from '@mui/material';
import usePageNavigation from '../../hooks/usePageNavigation';

import { queryLimit, PAYMENT_TYPES } from '../../constants';
import { CONSTANT_PAYMENT_METHODS_DICT } from '../constants';

import { getAllMyReceipts } from '../../services/requests/ApiRequests';

import CashRegisterForm from './CashRegisterForm';
import { getQueryParams } from '../../utils';

export default function CashLogs(props) {
    let navigate = useNavigate();
    const { t } = useTranslation();
    const { pageNumber = '1' } = useParams();
    const [journals, setJournals] = useState([]);
    const [loading, setLoading] = useState(true);
    const [selectedPaymentType, setSelectedPaymentType] = useState('');
    const [selectedStatusType, setSelectedStatusType] = useState('');
    const [selectedCashierId, setSelectedCashierId] = useState('');
    const [selectedCashierName, setSelectedCashierName] = useState('');
    const [selectedCashRegister, setSelectedCashRegister] = useState('');
    const [sortingDates, setSortingDates] = useState({
        postedFrom: null,
        postedTo: null,
    });
    const [currentPage, setCurrentPage] = useState(1);
    const [pageCount, setPageCount] = useState(null);
    const [cashStart, setCashStart] = useState(0);
    const [isCashRegisterAvailable, setIsCashRegisterAvailable] = useState(null);
    const [card, setCard] = useState({});
    const redirectLink = 'acps/cash-logs';

    usePageNavigation(
        setCurrentPage,
        setCashStart,
        pageNumber,
        queryLimit,
        pageCount,
        navigate,
        redirectLink,
    );

    const onSubmitCashRegisterForm = data => {
        setCard(data.formData);
        setIsCashRegisterAvailable(!!data.organization);
    };

    const handlePagination = (event, page) => {
        navigate(`/${redirectLink}/${page}`);
    };

    const getReceipts = () => {
        if (!isCashRegisterAvailable) return;
        const reqBody = {
            ...card,
            limit: queryLimit,
            start: cashStart,
        };
        getAllMyReceipts(reqBody).then(data => {
            const { data: resData, count: resCount } =
                data.data;
            setJournals(resData);
            setPageCount(Math.ceil(resCount / queryLimit));
            setLoading(false);
        });
    };

    useEffect(() => {
        getReceipts();
    }, [
        JSON.stringify(sortingDates),
        selectedCashRegister,
        selectedPaymentType,
        selectedStatusType,
        selectedCashierId,
        selectedCashierName,
        currentPage,
        isCashRegisterAvailable,
    ]);

    const queryParams = getQueryParams(card);

    return (
        <Box sx={{ flexGrow: 1, marginTop: 3, marginLeft: 0, marginRight: 3 }}>
            <CashRegisterForm onSubmit={onSubmitCashRegisterForm} />
            <Grid container spacing={2} align='left'>
                <Grid item xs={12}>
                    <TableContainer component={Paper}>
                        <Table sx={{ minWidth: 650 }} aria-label='simple table'>
                            <TableHead>
                                <TableRow>
                                    <TableCell>{t('id')}</TableCell>
                                    <TableCell align='right'>
                                        {t('receipt')} #
                                    </TableCell>
                                    <TableCell align='right'>
                                        {t('payment_type')}
                                    </TableCell>
                                    <TableCell align='right'>
                                        {t('date_time')}
                                    </TableCell>
                                    <TableCell align='right'>
                                        {t('total')}(MDL)
                                    </TableCell>
                                    <TableCell align='right'>
                                        {t('cashier_name')}
                                    </TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {loading ? (
                                    <TableRow>
                                        <TableCell colSpan={8}>
                                            <div align='center'>
                                                <CircularProgress size={35} />
                                            </div>
                                        </TableCell>
                                    </TableRow>
                                ) : journals.map(receipt => {
                                    if (receipt.status === 'synced') {
                                        return (
                                            <TableRow
                                                hover
                                                key={receipt.soap_data.id}
                                                sx={{
                                                    '&:last-child td, &:last-child th':
                                                        { border: 0 },
                                                }}
                                                onClick={() => {
                                                    navigate(
                                                        `/acps/receipt/${receipt._id}`,
                                                    );
                                                }}
                                            >
                                                <TableCell align='left'>
                                                    {receipt.soap_data.id}
                                                </TableCell>
                                                <TableCell align='right'>
                                                    {receipt.soap_data.receipt}
                                                </TableCell>
                                                <TableCell align='right'>
                                                    {
                                                        t(CONSTANT_PAYMENT_METHODS_DICT[
                                                            receipt.soap_data.data.payments.field[0].type
                                                        ].methodName)
                                                    }
                                                </TableCell>
                                                <TableCell align='right'>
                                                    {receipt.soap_data.datetime}
                                                </TableCell>
                                                <TableCell align='right'>
                                                    {
                                                        receipt.soap_data.data
                                                            .total
                                                    }
                                                </TableCell>
                                                <TableCell align='right'>
                                                    {receipt.cashier &&
                                                    receipt.cashier.name
                                                        ? receipt.cashier.name
                                                        : ' '}
                                                </TableCell>
                                            </TableRow>
                                        );
                                    } else {
                                        return (
                                            <TableRow
                                                hover
                                                onClick={() => {
                                                    navigate(
                                                        `/acps/receipt/${receipt._id}?${queryParams}`,
                                                    );
                                                }}
                                                key={receipt.soap_data.id}
                                                sx={{
                                                    '&:last-child td, &:last-child th':
                                                        { border: 0 },
                                                }}
                                            >
                                                <TableCell align='left'>
                                                    {receipt.soap_data.id}
                                                </TableCell>
                                                <TableCell align='right'>
                                                    {receipt.soap_data.receipt}
                                                </TableCell>
                                                <TableCell align='right'>
                                                    {
                                                        t(CONSTANT_PAYMENT_METHODS_DICT[
                                                            receipt.soap_data.data.payments.field[0].type
                                                        ].methodName)
                                                    }
                                                </TableCell>
                                                <TableCell align='right'>
                                                    {receipt.soap_data.datetime}
                                                </TableCell>
                                                <TableCell align='right'>
                                                    {
                                                        receipt.soap_data.data
                                                            .total
                                                    }
                                                </TableCell>
                                                <TableCell align='right'>
                                                    {receipt.status}
                                                </TableCell>
                                                <TableCell align='right'>
                                                    {receipt.cashier &&
                                                    receipt.cashier.id !== null
                                                        ? receipt.cashier.id
                                                        : ' '}
                                                </TableCell>
                                                <TableCell align='right'>
                                                    {receipt.cashier &&
                                                    receipt.cashier.name
                                                        ? receipt.cashier.name
                                                        : ' '}
                                                </TableCell>
                                            </TableRow>
                                        );
                                    }
                                })}
                            </TableBody>
                        </Table>
                    </TableContainer>
                </Grid>
                {pageCount > 1 && (
                    <Grid item xs={11} align='center'>
                        <Pagination
                            sx={{
                                my: 5,
                                justifyContent: 'center',
                                display: 'flex',
                            }}
                            count={pageCount}
                            variant='outlined'
                            shape='rounded'
                            page={+currentPage}
                            onChange={handlePagination}
                        />
                    </Grid>
                )}
            </Grid>
        </Box>
    );
}

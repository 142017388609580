import axios from "axios";
// import Cookies from "js-cookie";

const basePath = process.env.NODE_ENV === "development" 
    ? "http://127.0.0.1:8000/" 
    // ? "https://dev-mev.id.md/"
    : "/";

// required in case of opening a project where the backend will be run locally
// const basePath = process.env.NODE_ENV === "development" ? "http://0.0.0.0:9001/" : "/";

export function login(data) {

    let newConf = {
        url: `${basePath}data/login`,
        method: 'post',
        data: data,
    }

    const response = axios(newConf)
    return response;
}

export function getTaxReport(data, path) {
    const newConf = {
        url: `${basePath}data/report/taxes/${path}`,
        method: 'get',
        data: data,
        responseType: 'blob', 
    }

    const response = axios(newConf)
    return response;
}

export function upsertCashRegisterTax(data) {
    return axios({
        url: `${basePath}api/acps/cash_register/upsert_tax`,
        method: "post",
        data: data,
    });
}

export function removeCashRegisterTax(data) {
    const newConf = {
        url: `${basePath}api/acps/cash_register/delete_tax`,
        method: "post",
        data: data,
    };

    return axios(newConf);
}

export function getAllMyReceipts(data) {
    return axios.get(`${basePath}api/acps/fiscal_receipts`, { params: data });
}

export function getReceiptById(id, data) {
    return axios.get(`${basePath}api/acps/fiscal_receipt/${id}`, { params: data });
}

export function getOrganizationByCashRegister(data) {
    return axios.get(`${basePath}api/acps/organization_cash_register`, { params: data });
}

export const getAllMyReports = async (data) => {
    return axios.get(`${basePath}api/acps/reports`, { params: data });
};

export const getReportById = async (id, data) => {
    return axios.get(`${basePath}api/acps/report/${id}`, { params: data });
};

export const createNewReport = async (data) => {
    return axios({
        url: `${basePath}api/acps/create_report`,
        method: 'post',
        data,
    });
};

export const getAllMyServiceReceipts = async (data) => {
    return axios.get(`${basePath}api/acps/service_receipts`, { params: data });
};

export const getServiceReceiptById = (id, data) => {
    return axios.get(`${basePath}api/acps/service_receipt/${id}`, { params: data });
};
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';

import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';
import CircularProgress from '@mui/material/CircularProgress';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { Pagination } from '@mui/material';
import usePageNavigation from '../../hooks/usePageNavigation';

import { getAllMyServiceReceipts } from '../../services/requests/ApiRequests';
import CashRegisterForm from './CashRegisterForm';

import { queryLimit } from '../../constants';
import { getQueryParams } from '../../utils';

const RECIEPT_TYPES = {
    1: 'Input',
    2: 'Output',
}

export default function ServiceReceipts(props) {
    let navigate = useNavigate();
    const { t } = useTranslation();
    const { pageNumber = '1' } = useParams();
    const [sortingDates, setSortingDates] = useState({
        postedFrom: null,
        postedTo: null,
    });
    const [journal, setJournal] = useState([]);
    const [loading, setLoading] = useState(true);
    const [selectedCashierId, setSelectedCashierId] = useState('');
    const [selectedCashierName, setSelectedCashierName] = useState('');
    const [currentPage, setCurrentPage] = useState(1);
    const [pageCount, setPageCount] = useState(null);
    const [receiptsStart, setReceiptsStart] = useState(0);
    const [card, setCard] = useState(null);
    // const { organization, role } = props;
    const redirectLink = 'acps/service-receipts';

    usePageNavigation(
        setCurrentPage,
        setReceiptsStart,
        pageNumber,
        queryLimit,
        pageCount,
        navigate,
        redirectLink,
    );

    const handlePagination = (event, page) => {
        navigate(`/${redirectLink}/${page}`);
    };

    const getServiceReceipt = () => {
        if (!card) return;
        getAllMyServiceReceipts({
            ...sortingDates,
            limit: queryLimit,
            start: receiptsStart,
            ...card,
        }).then(data => {
            setJournal(data.data.data);
            setPageCount(
                Math.ceil(data.data.count / queryLimit),
            );
            setLoading(false);
        });
    };

    const onCashRegisterSubmit = data => {
        const { formData } = data;
        setCard(formData);
    };

    useEffect(() => {
        getServiceReceipt();
    }, [
        JSON.stringify(sortingDates),
        // selectedCashRegister,
        selectedCashierId,
        selectedCashierName,
        currentPage,
        card
    ]);

    return (
        <Box
            sx={{ flexGrow: 1, marginTop: 3, marginLeft: 0, marginBottom: 10 }}
        >
            <Grid container spacing={2} align='left'>
                <Grid item xs={5}>
                    <Typography
                        sx={{ fontSize: 24, marginTop: 3 }}
                        color='pimary'
                        gutterBottom
                    >
                        {t('service_checks')}:
                    </Typography>
                </Grid>
                <Grid item xs={6} sx={{ marginTop: 3 }} align='right'>
                    <Button
                        variant='outlined'
                        onClick={() => {
                            navigate('/acps/new-service-receipt');
                        }}
                    >
                        {t('create_new_service_receipt')}
                    </Button>
                </Grid>
            </Grid>
            <CashRegisterForm
                onSubmit={onCashRegisterSubmit}
            />
            <Grid container spacing={2} align='left'>
                <Grid item xs={11}>
                    <TableContainer component={Paper}>
                        <Table sx={{ minWidth: 650 }} aria-label='simple table'>
                            <TableHead>
                                <TableRow>
                                    <TableCell>{t('id')}</TableCell>
                                    <TableCell align='right'>
                                        {t('type')}
                                    </TableCell>
                                    <TableCell align='right'>
                                        {t('date_time')}
                                    </TableCell>
                                    <TableCell align='right'>
                                        {t('total')}
                                    </TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {journal.map(receipt => {
                                    return (
                                        <TableRow
                                            hover
                                            key={receipt.soap_data.id}
                                            sx={{
                                                '&:last-child td, &:last-child th':
                                                    { border: 0 },
                                            }}
                                            onClick={() => {
                                                navigate(
                                                    `/acps/service-receipt/${receipt._id}?${getQueryParams(card)}`,
                                                );
                                            }}
                                        >
                                            <TableCell align='left'>
                                                {receipt.soap_data.id}
                                            </TableCell>
                                            <TableCell align='right'>
                                                {RECIEPT_TYPES[receipt.soap_data.data.type]}
                                            </TableCell>
                                            <TableCell align='right'>
                                                {receipt.soap_data.datetime}
                                            </TableCell>
                                            <TableCell align='right'>
                                                {receipt.soap_data.data.total}
                                            </TableCell>
                                        </TableRow>
                                    );
                                })}
                            </TableBody>
                        </Table>
                    </TableContainer>
                </Grid>
                {pageCount > 1 && (
                    <Grid item xs={11} align='center'>
                        <Pagination
                            sx={{
                                mt: 5,
                                justifyContent: 'center',
                                display: 'flex',
                            }}
                            count={pageCount}
                            variant='outlined'
                            shape='rounded'
                            page={+currentPage}
                            onChange={handlePagination}
                        />
                    </Grid>
                )}
            </Grid>
        </Box>
    );
}

import './App.css';
import TopBar from './components/TopBar';
import AdminPanel from './components/AdminPanel';
import LoginPage from './components/LoginPage';

import {
  BrowserRouter as Router,
  Routes,
  Route,
} from "react-router-dom";
import { I18nextProvider } from 'react-i18next';

import ClientOffice from './components/ClientOffice';
import i18n from './i18n';

function App() {
  return (
    <div className="App">
      <I18nextProvider i18n={i18n}>
        <Router>
          <div>
            
            <TopBar />

            {/* A <Switch> looks through its children <Route>s and
                renders the first one that matches the current URL. */}
            <Routes>
              {/* <Route path="admin/*" element={<AdminPanel />} /> */}
              <Route path="/*" element={<AdminPanel />} />
              <Route path="data/login/" element={<LoginPage />} />
              <Route path="acps/*" element={<ClientOffice />} />
            </Routes>
          </div>
        </Router>
      </I18nextProvider>
      
    </div>
  );
}

export default App;

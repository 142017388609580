import React, { useRef, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useReactToPrint } from 'react-to-print';

import QRCode from "react-qr-code";
import CircularProgress from '@mui/material/CircularProgress';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Card from '@mui/material/Card';
import Divider from '@mui/material/Divider';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import PrintIcon from '@mui/icons-material/Print';

import { useParams, useSearchParams, useNavigate } from "react-router-dom";

import { getReportById } from '../../services/requests/ApiRequests';

import { REPORT_TYPES } from '../../constants';
import { CONSTANT_PAYMENT_METHODS_DICT } from '../constants';

export default function Report() {
    let params = useParams();
    const { t } = useTranslation();
    // const [id, setId] = React.useState({id: params.reportId});

    let navigate = useNavigate();
    const componentRef = useRef();
    const handlePrint = useReactToPrint({
      content: () => componentRef.current,
    });

    const [report, setReport] = React.useState({});
    const [loading, setLoading] = React.useState(true);

    const [searchParams] = useSearchParams();
    const searchParamsObj = Object.fromEntries(searchParams);


    useEffect(() => {
      getReportById(params.reportId, searchParamsObj).then((data) => {
          setReport(data.data);
          setLoading(false);
      });
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    function reportNumberResolve(num){
      let num_str = num.toString();
      let pad = "000000"
      let result = pad.substring(0, pad.length - num_str.length) + num_str
      return(result);
    }

    if (loading) {
      return (
        <div align='center'>
            <CircularProgress />
        </div>
      )
    }

    return (
    <Box sx={{ flexGrow: 1, marginTop: 5, marginLeft: 10 }}>
      <Grid container spacing={2} align='left'>
        <Grid item xs={5}>
          <Typography sx={{ fontSize: 28 }} color="pimary" gutterBottom>
            {t('viewing_report')}
          </Typography>
          <Button variant="text" color="secondary" onClick={() => { navigate("/acps/reports");}}>&#60; {t('back_to_report_log')}</Button>
          <Divider />
          <Grid container spacing={2} align='left'>
            <Grid item xs={8}>
              <Typography sx={{ fontSize: 20 }} color="pimary" gutterBottom>
                {t('information_about_check')}
              </Typography>
            </Grid>
            <Grid item xs={4}>
              <Typography sx={{ fontSize: 20 }} color="pimary" gutterBottom>
                {report.status}
              </Typography>
            </Grid>

            <Grid item xs={6}>
              <Typography sx={{ fontSize: 14 }} color="text.secondary" gutterBottom>
                {t('report_number')}
              </Typography>
            </Grid>
            <Grid item xs={6}>
              <Typography sx={{ fontSize: 14 }} color="pimary" gutterBottom>
                {report.soap_data.report}
              </Typography>
            </Grid>

            <Grid item xs={6}>
              <Typography sx={{ fontSize: 14 }} color="text.secondary" gutterBottom>
                {t('formation_date')}:
              </Typography>
            </Grid>
            <Grid item xs={6}>
              <Typography sx={{ fontSize: 14 }} color="pimary" gutterBottom>
                {report.soap_data.datetime.split("T")[0]} {report.soap_data.datetime.split("T")[1]}
              </Typography>
            </Grid>         

            <Grid item xs={6}>
              <Typography sx={{ fontSize: 14 }} color="text.secondary" gutterBottom>
                {t('title_kko')}:
              </Typography>
            </Grid>
            <Grid item xs={6}>
              <Typography sx={{ fontSize: 14 }} color="pimary" gutterBottom>
                {report.soap_data.card.name}
              </Typography>
            </Grid>           

            <Grid item xs={6}>
              <Typography sx={{ fontSize: 14 }} color="text.secondary" gutterBottom>
                {t('serial_number_kko')}:
              </Typography>
            </Grid>
            <Grid item xs={6}>
              <Typography sx={{ fontSize: 14 }} color="pimary" gutterBottom>
                {report.soap_data.card.number}
              </Typography>
            </Grid>

            <Grid item xs={6}>
              <Typography sx={{ fontSize: 14 }} color="text.secondary" gutterBottom>
                {t('address_kko')}:
              </Typography>
            </Grid>
            <Grid item xs={6}>
              <Typography sx={{ fontSize: 14 }} color="pimary" gutterBottom>
                {report.soap_data.card.address}
              </Typography>
            </Grid>

            <Grid item xs={12} align='center'>

              <Button variant="outlined" sx={{ width: '75%' }} startIcon={<PrintIcon />} onClick={handlePrint}>
                {t('print_report')}
              </Button>
            </Grid>

          </Grid>


        </Grid>
        <Grid item xs={6}>
          <Card sx={{ width: 375 }} ref={componentRef}>
              <CardContent>

                  <Typography variant="h5" component="h5" color="text.secondary" align='center'>
                  {report.soap_data.card.name}
                  </Typography>
                  <Typography sx={{ fontSize: 14 }} color="text.secondary" align='center' gutterBottom>
                  COD FISCAL: {report.soap_data.card.idnx}
                  </Typography>
                  <Typography sx={{ fontSize: 14 }} color="text.secondary" align='center' gutterBottom>
                  {report.soap_data.card.address}
                  </Typography>
                  <Grid container spacing={2} align='left'>
                      <Grid item xs={8}>
                          <Typography sx={{ fontSize: 14 }} color="text.secondary" gutterBottom>
                          NUMARUL DE INREGISTRARE:
                          </Typography>
                      </Grid>
                      <Grid item xs={4} align='right'>
                          <Typography sx={{ fontSize: 14 }} color="text.secondary" gutterBottom>
                          {report.soap_data.card.number}
                          </Typography>
                      </Grid>
                  </Grid>

                  <Grid container spacing={2} align='left'>
                      <Grid item xs={8}>
                          <Typography sx={{ fontSize: 14 }} color="text.secondary" gutterBottom>
                          SN:
                          </Typography>
                      </Grid>
                      <Grid item xs={4} align='right'>
                          <Typography sx={{ fontSize: 14 }} color="text.secondary" gutterBottom>
                          {report.soap_data.card.factory}
                          </Typography>
                      </Grid>
                  </Grid>

                  {report.soap_data.type === REPORT_TYPES.X &&
                    <Typography sx={{ fontSize: 14 }} color="text.secondary" align='center' gutterBottom>
                      RAPORT X
                    </Typography>
                  }

                  {report.soap_data.type === REPORT_TYPES.Z &&
                    <Typography sx={{ fontSize: 14 }} color="text.secondary" align='center' gutterBottom>
                      ZILNIC RAPORT Z
                    </Typography>
                  }
                    <Typography sx={{ fontSize: 14 }} color="text.secondary" align='center' gutterBottom>
                      VINZARI
                    </Typography>
                  

                  <Typography sx={{ fontSize: 14 }} color="text.secondary" align='center' gutterBottom>
                      
                  </Typography>

                  <Grid container spacing={2} align='left'>
                      <Grid item xs={8}>
                          <Typography sx={{ fontSize: 14 }} color="text.secondary" gutterBottom>
                            Cont.bonurilor:
                          </Typography>
                      </Grid>
                      <Grid item xs={4} align='right'>
                          <Typography sx={{ fontSize: 14 }} color="text.secondary" gutterBottom>
                            {report.soap_data.daily.receipts.field[0].count}
                            {/* количество чеков в отчёте \ за день */}
                          </Typography>
                      </Grid>
                  </Grid>
                  <Typography sx={{ fontSize: 14 }} color="text.secondary" align='center' gutterBottom>
                      
                  </Typography>
                  {/* Payment types */}
                  {report.soap_data.daily.payments.field.map((payments, index) => {
                    const paymentMethodName = CONSTANT_PAYMENT_METHODS_DICT[payments.type]
                      ? CONSTANT_PAYMENT_METHODS_DICT[payments.type].methodName
                      : payments.type;

                    return(
                      <React.Fragment key={index}>
                        <Grid container spacing={2} align='left'>
                            <Grid item xs={8}>
                                <Typography sx={{ fontSize: 14 }} color="text.secondary" gutterBottom>
                                {t(paymentMethodName)}:
                                </Typography>
                            </Grid>
                            <Grid item xs={4} align='right'>
                                <Typography sx={{ fontSize: 14 }} color="text.secondary" gutterBottom>
                                  {payments.total ? payments.total : "*"}
                                </Typography>
                            </Grid>
                        </Grid>
                        {/* <Grid container spacing={2} align='left'>
                            <Grid item xs={8}>
                                <Typography sx={{ fontSize: 14 }} color="text.secondary" gutterBottom>
                                &nbsp;&nbsp;&nbsp;&nbsp;TOTAL:
                                </Typography>
                            </Grid>
                            <Grid item xs={4} align='right'>
                                <Typography sx={{ fontSize: 14 }} color="text.secondary" gutterBottom>
                                {payments.total}
                                </Typography>
                            </Grid>
                        </Grid> */}
                      </React.Fragment>
                    )})
                  }

                  {/* Service receipt */}
                  <Grid container spacing={2} align='left'>
                      <Grid item xs={8}>
                          <Typography sx={{ fontSize: 14 }} color="text.secondary" gutterBottom>
                            INTRARI SERTAR:
                          </Typography>
                      </Grid>
                      <Grid item xs={4} align='right'>
                          <Typography sx={{ fontSize: 14 }} color="text.secondary" gutterBottom>
                          {report.soap_data.daily.totals.in}
                          </Typography>
                      </Grid>
                  </Grid>
                  <Grid container spacing={2} align='left'>
                      <Grid item xs={8}>
                          <Typography sx={{ fontSize: 14 }} color="text.secondary" gutterBottom>
                            IESIRI  SERTAR:
                          </Typography>
                      </Grid>
                      <Grid item xs={4} align='right'>
                          <Typography sx={{ fontSize: 14 }} color="text.secondary" gutterBottom>
                          {report.soap_data.daily.totals.out}
                          </Typography>
                      </Grid>
                  </Grid>
                  <Grid container spacing={2} align='left'>
                      <Grid item xs={8}>
                          <Typography sx={{ fontSize: 14 }} color="text.secondary" gutterBottom>
                            IN SAFEU:
                          </Typography>
                      </Grid>
                      <Grid item xs={4} align='right'>
                          <Typography sx={{ fontSize: 14 }} color="text.secondary" gutterBottom>
                          {report.soap_data.daily.totals.balance}
                          </Typography>
                      </Grid>
                  </Grid>

                  <Typography sx={{ fontSize: 14 }} color="text.secondary" align='center' gutterBottom>
                    GRUPE DE IMPOZIT
                  </Typography>

                  <Typography sx={{ fontSize: 14 }} color="text.secondary" align='center' gutterBottom>
                      - - - -
                  </Typography>

                  {report.soap_data.daily.taxes.field.map((tax, index) => {
                      return(
                        <React.Fragment key={index}>
                          <Grid container spacing={2} align='left'>
                              <Grid item xs={8}>
                                  <Typography sx={{ fontSize: 14 }} color="text.secondary" gutterBottom>
                                  Total {tax.code}:
                                  </Typography>
                              </Grid>
                              <Grid item xs={4} align='right'>
                                  <Typography sx={{ fontSize: 14 }} color="text.secondary" gutterBottom>
                                  {tax.cost}
                                  </Typography>
                              </Grid>
                          </Grid>

                          <Grid container spacing={2} align='left'>
                              <Grid item xs={8}>
                                  <Typography sx={{ fontSize: 14 }} color="text.secondary" gutterBottom>
                                  &nbsp;&nbsp;&nbsp;TVA {tax.code}={tax.code === 'F' ? t('no_vat') : `${tax.percent} %`}:
                                  </Typography>
                              </Grid>
                              <Grid item xs={4} align='right'>
                                  <Typography sx={{ fontSize: 14 }} color="text.secondary" gutterBottom>
                                  {tax.rate}
                                  </Typography>
                              </Grid>
                          </Grid>

                          <Grid container spacing={2} align='left'>
                              <Grid item xs={8}>
                                  <Typography sx={{ fontSize: 14 }} color="text.secondary" gutterBottom>
                                  &nbsp;&nbsp;&nbsp;Total net {tax.code}:
                                  </Typography>
                              </Grid>
                              <Grid item xs={4} align='right'>
                                  <Typography sx={{ fontSize: 14 }} color="text.secondary" gutterBottom>
                                  {(tax.cost-tax.rate).toFixed(2)}
                                  </Typography>
                              </Grid>
                          </Grid>
                        </React.Fragment>
                      )})
                    }

                  <Typography sx={{ fontSize: 14 }} color="text.secondary" align='center' gutterBottom>
                      - - - -
                  </Typography>

                  <Typography sx={{ fontSize: 14 }} color="text.secondary" align='center' gutterBottom>
                    TOTAL:
                  </Typography>

                  <Grid container spacing={2} align='left'>
                      <Grid item xs={8}>
                          <Typography sx={{ fontSize: 14 }} color="text.secondary" gutterBottom>
                            SUMA TOTALA:
                          </Typography>
                      </Grid>
                      <Grid item xs={4} align='right'>
                          <Typography sx={{ fontSize: 14 }} color="text.secondary" gutterBottom>
                            {report.soap_data.daily.total}
                          </Typography>
                      </Grid>
                  </Grid>
                  <Grid container spacing={2} align='left'>
                      <Grid item xs={8}>
                          <Typography sx={{ fontSize: 14 }} color="text.secondary" gutterBottom>
                            TOTAL TVA:
                          </Typography>
                      </Grid>
                      <Grid item xs={4} align='right'>
                          <Typography sx={{ fontSize: 14 }} color="text.secondary" gutterBottom>
                            {report.soap_data.daily.tax}
                          </Typography>
                      </Grid>
                  </Grid>
                  <Grid container spacing={2} align='left'>
                      <Grid item xs={8}>
                          <Typography sx={{ fontSize: 14 }} color="text.secondary" gutterBottom>
                            TOTAL NET:
                          </Typography>
                      </Grid>
                      <Grid item xs={4} align='right'>
                          <Typography sx={{ fontSize: 14 }} color="text.secondary" gutterBottom>
                          {report.soap_data.daily.untaxed}
                          </Typography>
                      </Grid>
                  </Grid>

                  {/* z report improvements */}
                  {report.soap_data.type === REPORT_TYPES.Z &&
                    <>
                      <Typography sx={{ fontSize: 14 }} color="text.secondary" align='center' gutterBottom>
                        * * * * *
                      </Typography>
                      <Grid container spacing={2} align='left'>
                        <Grid item xs={8}>
                            <Typography sx={{ fontSize: 14 }} color="text.secondary" gutterBottom>
                            TOTAL RULAJ MF:
                            </Typography>
                        </Grid>
                        <Grid item xs={4} align='right'>
                            <Typography sx={{ fontSize: 14 }} color="text.secondary" gutterBottom>
                            {report.soap_data.year.total}
                            </Typography>
                        </Grid>
                      </Grid>
                      <Grid container spacing={2} align='left'>
                        <Grid item xs={8}>
                            <Typography sx={{ fontSize: 14 }} color="text.secondary" gutterBottom>
                            TOTAL MF NET:
                            </Typography>
                        </Grid>
                        <Grid item xs={4} align='right'>
                            <Typography sx={{ fontSize: 14 }} color="text.secondary" gutterBottom>
                            {(report.soap_data.year.total - report.soap_data.year.tax).toFixed(2)}
                            </Typography>
                        </Grid>
                      </Grid>
                      <Grid container spacing={2} align='left'>
                        <Grid item xs={8}>
                            <Typography sx={{ fontSize: 14 }} color="text.secondary" gutterBottom>
                            TOTAL MF TVA:
                            </Typography>
                        </Grid>
                        <Grid item xs={4} align='right'>
                            <Typography sx={{ fontSize: 14 }} color="text.secondary" gutterBottom>
                            {report.soap_data.year.tax}
                            </Typography>
                        </Grid>
                      </Grid>
                    </>
                  }

                  {report.soap_data.type === REPORT_TYPES.Z &&
                    <>
                      <Typography sx={{ fontSize: 14 }} color="text.secondary" align='center' gutterBottom>
                        * * * * *
                      </Typography>
                      <Grid container spacing={2} align='left'>
                        <Grid item xs={8}>
                            <Typography sx={{ fontSize: 14 }} color="text.secondary" gutterBottom>
                            RULAJ ANUAL :
                            </Typography>
                        </Grid>
                        <Grid item xs={4} align='right'>
                            <Typography sx={{ fontSize: 14 }} color="text.secondary" gutterBottom>
                            {report.soap_data.year.total}
                            </Typography>
                        </Grid>
                      </Grid>
                      <Grid container spacing={2} align='left'>
                        <Grid item xs={8}>
                            <Typography sx={{ fontSize: 14 }} color="text.secondary" gutterBottom>
                            ANUAL NET:
                            </Typography>
                        </Grid>
                        <Grid item xs={4} align='right'>
                            <Typography sx={{ fontSize: 14 }} color="text.secondary" gutterBottom>
                            {(report.soap_data.year.total - report.soap_data.year.tax).toFixed(2)}
                            </Typography>
                        </Grid>
                      </Grid>
                      <Grid container spacing={2} align='left'>
                        <Grid item xs={8}>
                            <Typography sx={{ fontSize: 14 }} color="text.secondary" gutterBottom>
                            ANUAL TVA:
                            </Typography>
                        </Grid>
                        <Grid item xs={4} align='right'>
                            <Typography sx={{ fontSize: 14 }} color="text.secondary" gutterBottom>
                            {report.soap_data.year.tax}
                            </Typography>
                        </Grid>
                      </Grid>
                    </>
                  }

                  {report.soap_data.type === REPORT_TYPES.Z &&
                    <>
                      <Typography sx={{ fontSize: 14 }} color="text.secondary" align='center' gutterBottom>
                        * * * * *
                      </Typography>
                      <Grid container spacing={2} align='left'>
                        <Grid item xs={8}>
                            <Typography sx={{ fontSize: 14 }} color="text.secondary" gutterBottom>
                              RAPORT FISCAL ZILNIC
                            </Typography>
                        </Grid>
                        <Grid item xs={4} align='right'>
                            <Typography sx={{ fontSize: 14 }} color="text.secondary" gutterBottom>
                              {report.soap_data.report}
                            </Typography>
                        </Grid>
                      </Grid>
                    </>
                  }

                  <Typography sx={{ fontSize: 14 }} color="text.secondary" align='center' gutterBottom>

                  </Typography>

                  <Grid container spacing={2} align='left'>
                      <Grid item xs={12} align='left'>
                          <Typography sx={{ fontSize: 14 }} color="text.secondary" gutterBottom>
                          {reportNumberResolve(report._id)}
                          {/* текущий номер отчёта */}
                          </Typography>
                      </Grid>
                  </Grid>
                  <Grid container spacing={2} align='left'>
                      <Grid item xs={8}>
                          <Typography sx={{ fontSize: 14 }} color="text.secondary" gutterBottom>
                            DATA: {report.soap_data.datetime.split("T")[0]}
                          </Typography>
                      </Grid>
                      <Grid item xs={4} align='right'>
                          <Typography sx={{ fontSize: 14 }} color="text.secondary" gutterBottom>
                              ORA: {report.soap_data.datetime.split("T")[1]}
                          </Typography>
                      </Grid>
                  </Grid>

                  {/* QR */}
                  <Grid container spacing={2} align='center'>
                    <Grid item xs={12}>
                    </Grid>
                    <Grid item xs={12}>
                        <div id='qrcode'>
                          <QRCode size={80} value={`https://sift-testmev.sfs.md/report/${report.soap_data.url ? report.soap_data.url : report.soap_data.id}/`} />
                        </div>
                    </Grid>
                    <Grid item xs={12}>
                    </Grid>
                  </Grid>

                  <Grid container spacing={2} align='center'>
                    <Grid item xs={12}>
                        <Typography sx={{ fontSize: 14 }} color="text.secondary" gutterBottom>
                          ***  BON DE SERVICIU  ***
                        </Typography>
                    </Grid>
                  </Grid>

              </CardContent>

          </Card>
        </Grid>
      </Grid>
    </Box>
  )
}

import React from "react";

import {
    BrowserRouter as Router,
    Routes,
    Route,
    useNavigate,
} from "react-router-dom";

import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";

import Menu from "./admin_components/Menu";
import CompanyList from "./admin_components/CompanyList";
import TaxesLogs from "./admin_components/TaxesLogs";
import Company from "./admin_components/Company";
import NewCompany from "./admin_components/NewCompany";
import EditCompany from "./admin_components/EditCompany";
import CashRegister from "./admin_components/CashRegister";
import EditCashRegister from "./admin_components/EditCashRegister";
import AddCashRegister from "./admin_components/AddCashRegister";

import Cookies from "js-cookie";

export default function AdminPanel() {
    let navigate = useNavigate();
    const authCookie = Cookies.get("auth");

    React.useEffect(() => {
        if (!authCookie) {
            navigate("/data/login");
        }
    }, [authCookie, navigate]);

    return (
        <Box
            sx={{
                width: "100%",
                height: "90%",
                position: "absolute",
                marginTop: 9,
            }}
        >
            <Grid container spacing={3} align="left">
                <Grid item xs={1} md={1} lg={1}>
                    <Menu />
                </Grid>

                <Grid item xs={11} md={11} lg={11}>
                    <Routes>
                        <Route path="/" element={<CompanyList />} />
                        <Route path="companies" element={<CompanyList />} />
                        <Route path="company" element={<Company />}>
                            <Route path=":companyId" element={<Company />} />
                        </Route>
                        <Route path="edit-company" element={<EditCompany />}>
                            <Route
                                path=":companyId"
                                element={<EditCompany />}
                            />
                        </Route>
                        <Route path="new-company" element={<NewCompany />} />
                        <Route
                            path="add-cash-register"
                            element={<AddCashRegister />}
                        >
                            <Route
                                path=":companyId"
                                element={<AddCashRegister />}
                            />
                        </Route>
                        <Route path="cash-register" element={<CashRegister />}>
                            <Route path=":companyId" element={<CashRegister />}>
                                <Route
                                    path=":cashRegisterId"
                                    element={<CashRegister />}
                                />
                            </Route>
                        </Route>
                        <Route
                            path="edit-cash-register"
                            element={<EditCashRegister />}
                        >
                            <Route
                                path=":companyId"
                                element={<EditCashRegister />}
                            >
                                <Route
                                    path=":cashRegisterId"
                                    element={<EditCashRegister />}
                                />
                            </Route>
                        </Route>
                        <Route path="taxes" element={<TaxesLogs />} />

                        {/* <Route path="new-cash-register" element={<NewCashRegister />} /> */}
                        {/* <Route path="users" element={<UserList />} />
                        <Route path="user" element={<User />} >
                            <Route path=":userId" element={<User />} />
                        </Route>
                        <Route path="new-user" element={<NewUser />} /> */}
                    </Routes>

                    {/* <NewReceipt /> */}
                </Grid>
            </Grid>
        </Box>
    );
}

import axios from "axios";

const basePath =
    process.env.NODE_ENV === "development"
        ? "http://127.0.0.1:8000/"
        // ? 'https://dev-mev.id.md/'
        :  "/";

// required in case of opening a project where the backend will be run locally
// const basePath = process.env.NODE_ENV === 'development' ? 'http://0.0.0.0:9001/' : '/';

export function createNewFiscalReceipt(data, token) {
    console.log('data', data);
    let newConf = {
        url: `${basePath}api/acps/create_fiscal_receipt`,
        method: "post",
        // headers: { Authorization: `Bearer ${token}` },
        data: data,
    };

    const response = axios(newConf);
    return response;
}

// POST add new fiscal receipt
export function createNewReceipt(data, token, client) {
    console.log('data', data);
    let newConf = {
        url: `${basePath}api/acps/fiscal_receipt/${client}/`,
        method: "post",
        headers: { Authorization: `Bearer ${token}` },
        data: data,
    };

    const response = axios(newConf);
    return response;
}

// POST add new service receipt
export function createNewServiceReceipt(data) {
    let newConf = {
        url: `${basePath}api/acps/create_service_receipt`,
        method: "post",
        // headers: { Authorization: `Bearer ${token}` },
        data: data,
    };

    const response = axios(newConf);
    return response;
}

import React, { useState, useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';

import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import TextField from '@mui/material/TextField';
import MenuItem from '@mui/material/MenuItem';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import IconButton from '@mui/material/IconButton';
import ModeOutlinedIcon from '@mui/icons-material/ModeOutlined';
import DeleteForeverOutlinedIcon from '@mui/icons-material/DeleteForeverOutlined';
import { Checkbox, FormControlLabel } from '@mui/material';

import CashRegisterForm from './CashRegisterForm';

import {
    removeCashRegisterTax,
    upsertCashRegisterTax,
} from '../../services/requests/ApiRequests';
import { ROLE, TAX_CODES } from '../../constants';
import { valueToFixed } from '../../services/toFixed';

export default function Taxes(props) {
    const { t } = useTranslation();
    const formRef = useRef();
    const [taxesList, setTaxesList] = useState([]);
    const [taxAvailableCodes, setTaxAvailableCodes] = useState(TAX_CODES);
    const [openTaxEditDialog, serOpenTaxEditDialog] = useState(false);
    const [openTaxAddDialog, serOpenTaxAddDialog] = useState(false);
    const [openTaxRemoveDialog, serOpenTaxRemoveDialog] = useState(false);
    const [card, setCard] = useState({});
    const [organization, setOrganization] = useState(null);
    const [taxSelected, setTaxSelected] = useState({
        id: 0,
        code: '',
        title: '',
        rate: 0,
        percent: '',
        enabled: false,
    });

    useEffect(() => {
        const taxes = organization?.cash_registers?.[0]?.taxes;
        if (taxes) {
            setTaxesList(taxes);
        }
    }, [organization]);


    useEffect(() => {
        const codesArray = taxesList?.map(tax => tax.code);
        setTaxAvailableCodes(taxAvailableCodes =>
            taxAvailableCodes.filter(el => !codesArray.includes(el)),
        );
    }, [taxesList]);

    const onSubmitCashRegisterForm = data => {
        setCard(data.formData);
        setOrganization(data.organization);
    };

    function createCodesList(taxes) {
        const codesArray = taxes?.map(tax => tax.code);
        setTaxAvailableCodes(
            taxAvailableCodes.filter(el => !codesArray.includes(el)),
        );
    }

    function editTax(tax) {
        const requestBody = {
            card: {
                ...card,
            },
            tax,
        };
        upsertCashRegisterTax(requestBody).then(
            data => {
                if (data.data.taxes) {
                    const taxes = data.data.taxes;
                    serOpenTaxAddDialog(false);
                    setTaxesList(taxes);
                    handleCloseDialog();
                }
            },
        );
    }

    function handleTaxRemove() {
        const requestBody = {
            card: {
                ...card,
            },
            tax_id: taxSelected._id,
        }

        removeCashRegisterTax(requestBody).then(
            data => {
                if (data.data.taxes) {
                    const taxes = data.data.taxes;
                    serOpenTaxRemoveDialog(false);
                    setTaxesList(taxes);
                    setTaxAvailableCodes(
                        [...taxAvailableCodes, taxSelected.code].sort(),
                    );
                    handleCloseDialog();
                }
            },
        );
    }

    function handleChangeTax(event) {
        setTaxSelected({
            ...taxSelected,
            ...{ percent: valueToFixed(event.target.value, 99) },
        });
    }

    function handleChangeField(event, name) {
        let newfield = {};
        if (name === 'percent') {
            newfield[name] = valueToFixed(event.target.value, 99);
        } else {
            newfield[name] = event.target.value;
        }
        setTaxSelected({ ...taxSelected, ...newfield });
    }

    function handleTaxAdd(e) {
        e.preventDefault();
        formRef.current.reportValidity();
        if (formRef.current.checkValidity()) {
            const requestBody = {
                card: {
                    ...card,
                },
                tax: taxSelected,
            };
            upsertCashRegisterTax(requestBody).then(
                data => {
                    if (data.data.taxes) {
                        const taxes = data.data.taxes;
                        serOpenTaxAddDialog(false);
                        setTaxesList(taxes);
                        createCodesList(taxes);
                        handleCloseDialog();
                    }
                },
            );
        }
    }

    function handleCloseDialog() {
        if (openTaxAddDialog) serOpenTaxAddDialog(false);
        if (openTaxEditDialog) serOpenTaxEditDialog(false);
        if (openTaxRemoveDialog) serOpenTaxRemoveDialog(false);
        setTaxSelected({
            id: 0,
            code: '',
            title: '',
            rate: 0,
            percent: '0',
            enabled: false,
        });
    }

    function handleTaxChnage(e, tax) {
        const changedTax = { ...tax, enabled: e.target.checked };
        setTaxSelected(changedTax);
        editTax(changedTax);
    }

    if (props.role === ROLE.cashier) return null;

    return (
        <Box
            sx={{ flexGrow: 1, marginTop: 3, marginLeft: 0, marginBottom: 10 }}
        >
            <Grid container spacing={2} align='left'>
                <Grid item xs={7}>
                    <CashRegisterForm onSubmit={onSubmitCashRegisterForm} />
                </Grid>
                <Grid item xs={4} sx={{ marginLeft: 1 }}>
                    <Typography
                        sx={{ fontSize: 24, marginTop: 6, marginBottom: 3 }}
                        color='pimary'
                        gutterBottom
                    >
                        {t('taxes_list')}:
                    </Typography>
                    {taxesList?.map(item => {

                        return (
                            <div key={item._id}>
                                <Box sx={{ borderTop: '1px solid #ddd' }}>
                                    <FormControlLabel
                                        control={<Checkbox />}
                                        label={`${
                                            item.code
                                        } ${item.percent.toFixed(2)}%`}
                                        checked={item.enabled}
                                        name={item.title}
                                        sx={{
                                            width: 'calc( 100% - 110px)',
                                            marginTop: 1,
                                            paddingBottom: 1,
                                        }}
                                        onChange={e => handleTaxChnage(e, item)}
                                    />
                                    <IconButton
                                        aria-label='edit'
                                        size='large'
                                        onClick={e => {
                                            setTaxSelected(item);
                                            serOpenTaxEditDialog(true);
                                        }}
                                    >
                                        <ModeOutlinedIcon />
                                    </IconButton>
                                    <IconButton
                                        aria-label='delete'
                                        size='large'
                                        onClick={e => {
                                            setTaxSelected(item);
                                            serOpenTaxRemoveDialog(true);
                                        }}
                                    >
                                        <DeleteForeverOutlinedIcon />
                                    </IconButton>
                                </Box>
                            </div>
                        );
                    })}
                    {organization && (
                        <Button
                            variant='outlined'
                            sx={{
                                marginTop: 2,
                                width: '100%',
                            }}
                            onClick={() => serOpenTaxAddDialog(true)}
                        >
                            {t('new_taxes_btn')}
                        </Button>
                    )}
                </Grid>
            </Grid>

            {/* <Snackbar
                open={error.status}
                autoHideDuration={6000}
                onClose={handleClose}
            >
                <Alert
                    onClose={handleClose}
                    severity='error'
                    sx={{ width: '100%' }}
                >
                    {error.msg}
                </Alert>
            </Snackbar> */}

            <Dialog
                open={openTaxRemoveDialog}
                onClose={handleCloseDialog}
                aria-labelledby='alert-dialog-title'
                aria-describedby='alert-dialog-description'
            >
                <DialogTitle id='alert-dialog-title'>
                    {t('Do you want to delete this tax?')}
                </DialogTitle>
                <DialogContent>
                    <Button
                        variant='contained'
                        onClick={handleTaxRemove}
                        sx={{ marginTop: 2 }}
                    >
                        Yes
                    </Button>
                    <Button
                        variant='outlined'
                        sx={{ marginTop: 2, marginLeft: 2 }}
                        onClick={handleCloseDialog}
                    >
                        Cancel
                    </Button>
                </DialogContent>
            </Dialog>

            <Dialog
                open={openTaxEditDialog}
                onClose={handleCloseDialog}
                // aria-labelledby='alert-dialog-title'
                // aria-describedby='alert-dialog-description'
            >
                <DialogTitle>{t('Edit tax')}</DialogTitle>
                <DialogContent>
                    <TextField
                        id='outlined-basic'
                        label='Percent'
                        onChange={event => handleChangeTax(event)}
                        fullWidth
                        sx={{ marginTop: 1 }}
                        variant='standard'
                        value={parseFloat(taxSelected.percent).toFixed(2)}
                    />
                    <Button
                        variant='outlined'
                        sx={{ marginTop: 2 }}
                        onClick={handleCloseDialog}
                    >
                        Cancel
                    </Button>
                    <Button
                        variant='contained'
                        onClick={() => editTax(taxSelected)}
                        sx={{ marginTop: 2, marginLeft: 2 }}
                    >
                        Сохранить
                    </Button>
                </DialogContent>
            </Dialog>

            <Dialog
                open={openTaxAddDialog}
                onClose={handleCloseDialog}
                aria-labelledby='alert-dialog-title'
                aria-describedby='alert-dialog-description'
            >
                <DialogTitle id='alert-dialog-title'>
                    {t('Add tax')}
                </DialogTitle>
                <DialogContent>
                    <Box
                        component='form'
                        sx={{
                            '& .MuiTextField-root': {
                                marginTop: 2,
                                width: '25ch',
                            },
                        }}
                        noValidate
                        onSubmit={handleTaxAdd}
                        ref={formRef}
                    >
                        <TextField
                            select
                            label='Code'
                            onChange={event => handleChangeField(event, 'code')}
                            fullWidth
                            sx={{ marginTop: 2 }}
                            variant='standard'
                            defaultValue={taxSelected.code}
                            required
                            error={
                                taxesList.filter(
                                    el => el.code === taxSelected.code,
                                ).length !== 0
                            }
                            helperText={
                                taxesList.filter(
                                    el => el.code === taxSelected.code,
                                ).length === 0
                                    ? 'Need to be selected'
                                    : 'This value exists'
                            }
                        >
                            {taxAvailableCodes.map(option => (
                                <MenuItem key={option} value={option}>
                                    {option}
                                </MenuItem>
                            ))}
                        </TextField>
                        {/* <TextField
                            label={t('Title')}
                            onChange={event => handleChangeField(event, 'title')}
                            name='title'
                            variant='standard'
                            sx={{ marginTop: 2, marginLeft: 2 }}
                            fullWidth
                            defaultValue={taxSelected.title}
                            helperText="Enter the value"
                        /> */}
                        <TextField
                            label={t('Percent')}
                            onChange={event =>
                                handleChangeField(event, 'percent')
                            }
                            name='percent'
                            variant='standard'
                            sx={{ marginTop: 2, marginLeft: 2 }}
                            fullWidth
                            value={taxSelected.percent}
                            required
                            error={
                                taxesList.filter(
                                    el =>
                                        parseFloat(el.percent) ===
                                        parseFloat(taxSelected.percent),
                                ).length !== 0
                            }
                            helperText={
                                taxesList.filter(
                                    el =>
                                        parseFloat(el.percent) ===
                                        parseFloat(taxSelected.percent),
                                ).length === 0
                                    ? 'Enter the value'
                                    : 'This value exists'
                            }
                        />
                        {/* <TextField
                            label={t('Rate')}
                            onChange={event => handleChangeField(event, 'rate')}
                            name='rate'
                            variant='standard'
                            sx={{ marginTop: 2, marginLeft: 2 }}
                            fullWidth
                            defaultValue={taxSelected.rate}
                            required
                            helperText="Enter the value"
                        /> */}
                        <Button
                            variant='outlined'
                            sx={{ marginTop: 2 }}
                            onClick={handleCloseDialog}
                        >
                            Cancel
                        </Button>
                        <Button
                            type='submit'
                            variant='contained'
                            sx={{ marginTop: 2, marginLeft: 2 }}
                        >
                            Сохранить
                        </Button>
                    </Box>
                </DialogContent>
            </Dialog>
        </Box>
    );
}

import React, { useState } from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";
import Button from "@mui/material/Button";

import RU from "../../services/translation/ru.json";
import { getTaxReport } from "../../services/requests/ApiRequests";

export default function TaxesLogs() {
    const [loading, setLoading] = useState(false);
    const [dict, setDict] = useState(RU);

    const getLogFile = (filetype, path) => {
        getTaxReport(filetype, path)
            .then(response => {
                const url = window.URL.createObjectURL(new Blob([response.data]));
                const link = document.createElement('a');
                link.href = url;
                link.setAttribute('download', `taxes_repot.${filetype}`);
                document.body.appendChild(link);
                link.click();
                link.parentNode.removeChild(link);
            })
            .catch(err => {
                alert(err);
            });
    };

    return (
        <Box
            sx={{ flexGrow: 1, marginTop: 3, marginLeft: 10, marginBottom: 10 }}
        >
            <Grid container sx={{ marginTop: 2 }} spacing={4} align="left">
                <Grid item xs={12}>
                    <Typography
                        sx={{ fontSize: 24 }}
                        color="pimary"
                        gutterBottom
                    >
                         {dict.taxes_logs}
                    </Typography>
                </Grid>
                <Grid item xs={12}>
                    <Button
                        variant="outlined"
                        onClick={() => getLogFile('xlsx', 'excel')}
                        component="button"
                        sx={{
                            textDecoration: 'none',
                            textTransform: 'uppercase',
                            padding: '5px 10px',
                            border: '1px solid blue'
                        }}
                    >
                        {dict.download_taxes_logs_excel}
                    </Button>
                    <Button
                        variant="outlined"
                        onClick={() => getLogFile('csv', 'csv')}
                        component="button"
                        sx={{
                            textDecoration: 'none',
                            textTransform: 'uppercase',
                            padding: '5px 10px',
                            border: '1px solid blue',
                            marginLeft: '10px'
                        }}
                    >
                        {dict.download_taxes_logs_csv}
                    </Button>
                </Grid>
            </Grid>
        </Box>
    );
}

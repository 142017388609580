import React from "react";

import Cookies from "js-cookie";
import { useTranslation } from "react-i18next";

import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";
import Button from "@mui/material/Button";
import CircularProgress from "@mui/material/CircularProgress";
import Alert from "@mui/material/Alert";
import Snackbar from "@mui/material/Snackbar";
import TextField from "@mui/material/TextField";
import { Checkbox, FormControlLabel, sliderClasses } from '@mui/material';

import { useParams } from "react-router-dom";
import { useNavigate } from "react-router-dom";

import { getCashById } from "../../services/requests/GraphRequests";
import { createNewReport } from '../../services/requests/ApiRequests';
import { REPORT_TYPES } from '../../constants';
import { translation } from "../../services/translation/translation";
import RU from "../../services/translation/ru.json";
import { getQueryParams } from '../../utils';


export default function CashRegister() {
    const { t } = useTranslation();
    let navigate = useNavigate();
    let params = useParams();
    const { cashRegisterId = "", companyId = "" } = params || {};

    const [loading, setLoading] = React.useState(true);
    const [companyWithCash, setCompanyWithCash] = React.useState({});

    const [dict, setDict] = React.useState(RU);
    React.useEffect(() => {
        getCashById(companyId, cashRegisterId)
            .then((data) => {
                const organization = data.data.organization;
                const filteredCashRegisters = organization.cashRegisters.filter(
                    (cashRegister) =>
                        cashRegister.id === parseInt(cashRegisterId)
                );



                const updatedOrganization = {
                    ...organization,
                    cashRegisters: filteredCashRegisters,
                };

                setCompanyWithCash(updatedOrganization);
                setLoading(false);
            })

            .catch((error) => {
                console.warn(error);
                setLoading(false);
            });
        if (Cookies.get("simpalsid.lang")) {
            setDict(translation(Cookies.get("simpalsid.lang")));
        } else {
            setDict(translation("ru"));
        }
    }, [cashRegisterId, companyId]);

    const [openSuccess, setOpenSuccess] = React.useState(false);
    const [error, setError] = React.useState({
        status: false,
        msg: "Error",
    });
    const handleClose = (event, reason) => {
        if (reason === "clickaway") {
            return;
        }

        setOpenSuccess(false);
        setError({
            status: false,
            msg: "Error",
        });
    };

    function generateReport(type) {
        setLoading(true);
        const cashRegister = companyWithCash.cashRegisters[0];
        const card = {
            type: 3,
            name: companyWithCash.name,
            idnx: companyWithCash.idno,
            model: cashRegister.model,
            address: cashRegister.address,
            factory: cashRegister.factory,
            number: cashRegister.number,
            key: cashRegister.key,
            point: cashRegister.point,
        };
        const reqBody = { card, type };
        createNewReport(reqBody).then(data => {

            const reports = data.data?.data || [];
            if (reports.length) {
                // setLoading(true);
                // navigate(`/acps/report/${data.data.data[0].id}`);
                const newReport = reports[0];
                window.open(`/acps/report/${newReport._id}?${getQueryParams(card)}`, '_blank');
            } else {
                setError({
                    status: true,
                    msg: 'There are errors while report generation',
                });
            }
            setLoading(false);
        }).catch(error => {
            console.log(error)
            const data = error?.response?.data || {};
            const msg = data.error || 'There are errors while report generation';
            setError({ status: true, msg });
            setLoading(false);
        });
    }

    React.useEffect(() => {
        if (openSuccess && loading) {
            setTimeout(function () {
                getCashById(cashRegisterId).then((data) => {
                    setCompanyWithCash(data.data.organization);
                });
                setLoading(false);
            }, 300);
        }
    }, [cashRegisterId, loading, openSuccess, setOpenSuccess]);

    if (loading) {
        return (
            <Grid sx={{ marginTop: 4 }}>
                <div align="center">
                    <CircularProgress />
                </div>
            </Grid>
        );
    }

    if (!companyWithCash || !companyWithCash.cashRegisters[0]) return null;

    return (
        <Box
            sx={{ flexGrow: 1, marginTop: 3, marginLeft: 10, marginBottom: 10 }}
        >
            <Grid container sx={{ marginTop: 2 }} spacing={4} align="left">
                <Grid item xs={6}>
                    <Typography
                        sx={{ fontSize: 24 }}
                        color="pimary"
                        gutterBottom
                    >
                        {dict.cash_machine} №{" "}
                        {companyWithCash.cashRegisters[0].id}
                    </Typography>
                    <Button
                        variant="text"
                        color="secondary"
                        onClick={() => {
                            navigate(`/company/${companyWithCash.id}`);
                        }}
                    >
                        &#60; {dict.go_back}
                    </Button>
                    {/* Organization info */}
                    <Grid container spacing={2} align="left">
                        <Grid item xs={4}>
                            <Typography
                                sx={{ fontSize: 14 }}
                                color="text.secondary"
                                gutterBottom
                            >
                                {dict.org_name}:
                            </Typography>
                        </Grid>
                        <Grid item xs={8}>
                            <Typography
                                sx={{ fontSize: 14 }}
                                color="pimary"
                                gutterBottom
                            >
                                {companyWithCash.name}
                            </Typography>
                        </Grid>
                        <Grid item xs={4}>
                            <Typography
                                sx={{ fontSize: 14 }}
                                color="text.secondary"
                                gutterBottom
                            >
                                {dict.idno} :
                            </Typography>
                        </Grid>
                        <Grid item xs={8}>
                            <Typography
                                sx={{ fontSize: 14 }}
                                color="pimary"
                                gutterBottom
                            >
                                {companyWithCash.idno}
                            </Typography>
                        </Grid>
                        <Grid item xs={4}>
                            <Typography
                                sx={{ fontSize: 14 }}
                                color="text.secondary"
                                gutterBottom
                            >
                                {dict.contact} :
                            </Typography>
                        </Grid>
                        <Grid item xs={8}>
                            <Typography
                                sx={{ fontSize: 14 }}
                                color="pimary"
                                gutterBottom
                            >
                                {companyWithCash.contact}
                            </Typography>
                        </Grid>
                        <Grid item xs={4}>
                            <Typography
                                sx={{ fontSize: 14 }}
                                color="text.secondary"
                                gutterBottom
                            >
                                {dict.phone_number} :
                            </Typography>
                        </Grid>
                        <Grid item xs={8}>
                            <Typography
                                sx={{ fontSize: 14 }}
                                color="pimary"
                                gutterBottom
                            >
                                {companyWithCash.contactPhone}
                            </Typography>
                        </Grid>
                        <Grid item xs={4}>
                            <Typography
                                sx={{ fontSize: 14 }}
                                color="text.secondary"
                                gutterBottom
                            >
                                {dict.email} :
                            </Typography>
                        </Grid>
                        <Grid item xs={8}>
                            <Typography
                                sx={{ fontSize: 14 }}
                                color="pimary"
                                gutterBottom
                            >
                                {companyWithCash.contactEmail}
                            </Typography>
                        </Grid>
                        {companyWithCash.organizationPhone && (
                            <>
                                <Grid item xs={4}>
                                    <Typography
                                        sx={{ fontSize: 14 }}
                                        color="text.secondary"
                                        gutterBottom
                                    >
                                        {dict.organization_phone} :
                                    </Typography>
                                </Grid>
                                <Grid item xs={8}>
                                    <Typography
                                        sx={{ fontSize: 14 }}
                                        color="pimary"
                                        gutterBottom
                                    >
                                        {companyWithCash.organizationPhone}
                                    </Typography>
                                </Grid>
                            </>
                        )}
                        {companyWithCash.organizationEmail && (
                            <>
                                <Grid item xs={4}>
                                    <Typography
                                        sx={{ fontSize: 14 }}
                                        color="text.secondary"
                                        gutterBottom
                                    >
                                        {dict.organization_email} :
                                    </Typography>
                                </Grid>
                                <Grid item xs={8}>
                                    <Typography
                                        sx={{ fontSize: 14 }}
                                        color="pimary"
                                        gutterBottom
                                    >
                                        {companyWithCash.organizationEmail}
                                    </Typography>
                                </Grid>
                            </>
                        )}
                    </Grid>
                </Grid>
                <Grid item xs={5} align="right">
                    <Button
                        variant="outlined"
                        onClick={() => {
                            navigate(
                                `/edit-cash-register/${companyId}/${cashRegisterId}`
                            );
                        }}
                    >
                        {dict.edit_cash_register}
                    </Button>
                </Grid>
            </Grid>
            <Grid container sx={{ marginTop: 2 }} spacing={4} align="left">
                <Grid item xs={12}>
                <Button
                        variant='outlined'
                        onClick={() => generateReport(REPORT_TYPES.Z)}
                        sx={{ marginTop: 2 }}
                    >
                        {t('z_report')}
                    </Button>
                    <Button
                        variant='outlined'
                        onClick={() => generateReport(REPORT_TYPES.X)}
                        sx={{ marginTop: 2, marginLeft: 1 }}
                    >
                        {t('x_report')}
                    </Button>
                </Grid>
                <Grid item xs={5}>
                    <TextField
                        sx={{ m: 1, width: "100%" }}
                        id="Address"
                        label={dict.address}
                        value={companyWithCash.cashRegisters[0].address}
                        variant="standard"
                    />

                    <TextField
                        sx={{ m: 1, width: "100%" }}
                        id="Model"
                        label="Model"
                        value={companyWithCash.cashRegisters[0].model}
                        variant="standard"
                    />
                    <TextField
                        sx={{ m: 1, width: "100%" }}
                        id="Point"
                        label={dict.point}
                        value={companyWithCash.cashRegisters[0].point}
                        variant="standard"
                    />
                    <FormControlLabel
                        control={<Checkbox disabled />}
                        label='Disabled'
                        checked={companyWithCash.cashRegisters[0].deleted}
                    />
                </Grid>
                <Grid item xs={6}>
                    <TextField
                        sx={{ m: 1, width: "100%" }}
                        id="Factory Number"
                        label={dict.factory_number}
                        value={companyWithCash.cashRegisters[0].factory}
                        variant="standard"
                    />

                    <TextField
                        sx={{ m: 1, width: "100%" }}
                        id="Registration Number"
                        label={dict.registration_number}
                        value={companyWithCash.cashRegisters[0].number}
                        variant="standard"
                    />

                    <TextField
                        sx={{ m: 1, width: "100%" }}
                        id="Key"
                        label={dict.key}
                        value={companyWithCash.cashRegisters[0].key}
                        variant="standard"
                    />
                    <TextField
                        sx={{ m: 1, width: "100%" }}
                        id="Subdivision"
                        label={dict.subdivision}
                        value={companyWithCash.cashRegisters[0].subdivision}
                        variant="standard"
                    />
                </Grid>
            </Grid>
            <Snackbar
                open={openSuccess}
                autoHideDuration={6000}
                onClose={handleClose}
            >
                <Alert
                    onClose={handleClose}
                    severity="success"
                    sx={{ width: "100%" }}
                >
                    Api key refreshed !
                </Alert>
            </Snackbar>
            <Snackbar
                open={error.status}
                autoHideDuration={6000}
                onClose={handleClose}
            >
                <Alert
                    onClose={handleClose}
                    severity="error"
                    sx={{ width: "100%" }}
                >
                    {error.msg}
                </Alert>
            </Snackbar>
        </Box>
    );
}

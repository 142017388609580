import React from 'react';
import { useTranslation } from 'react-i18next';

import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";
import Button from "@mui/material/Button";
import MenuItem from '@mui/material/MenuItem';
import Alert from "@mui/material/Alert";
import Snackbar from "@mui/material/Snackbar";
import TextField from "@mui/material/TextField";
import Input from "@mui/material/Input";
import InputLabel from "@mui/material/InputLabel";
import FormControl from "@mui/material/FormControl";
import FormHelperText from "@mui/material/FormHelperText";

import useCashRegisterForm from './hooks/useCashRegisterForm';

const OPERATORS = ['1', '2', '3', '4', '5', '6', '7', '8', '9', '10'];

const CashRegisterForm = ({
  onSubmit,
}) => {
  const {
    formData,
    handleChange,
    handleSubmit,
    // organization,
    error,
    openSuccess,
    handleClose,
} = useCashRegisterForm({ onSubmit });
  const { t } = useTranslation();

  return (
    <>
    <Grid container sx={{ marginTop: 2 }} spacing={2} align="left">
      <Grid item xs={5}>
        <Typography
            sx={{ fontSize: 24, marginTop: 2 }}
            color='pimary'
            gutterBottom
        >
            {t('cash_machine')}:
        </Typography>
      </Grid>
    </Grid>
    <Grid container sx={{ marginTop: 2, marginBottom: 2 }} spacing={2} align="left">
      <Grid item xs={5}>
        <FormControl fullWidth sx={{ m: 1 }} variant="standard">
          <InputLabel htmlFor="component-name">
            {t('name')}
          </InputLabel>
          <Input
            id="component-name"
            value={formData.name}
            onChange={handleChange}
            name="name"
            aria-describedby="name-helper-text"
            inputProps={{
              "aria-label": "text",
            }}
          />
          <FormHelperText id="name-helper-text">
            {t('must_not_empty')}
          </FormHelperText>
        </FormControl>
        <FormControl fullWidth sx={{ m: 1 }} variant="standard">
          <InputLabel htmlFor="component-idnx">
            {t('idnx')}
          </InputLabel>
          <Input
            id="component-idnx"
            value={formData.idnx}
            onChange={handleChange}
            name="idnx"
            aria-describedby="idnx-helper-text"
            inputProps={{
              "aria-label": "text",
            }}
          />
          <FormHelperText id="idnx-helper-text">
            {t('must_not_empty')}
          </FormHelperText>
        </FormControl>
        <FormControl fullWidth sx={{ m: 1 }} variant="standard">
          <InputLabel htmlFor="component-simple">
            {t('address')}
          </InputLabel>
          <Input
            id="component-simple"
            value={formData.address}
            onChange={handleChange}
            name="address"
            aria-describedby="standard-helper-text"
            inputProps={{
              "aria-label": "text",
            }}
            // error={formValidationError.address}
          />
          <FormHelperText id="standard-helper-text">
            {t('must_not_empty')}
          </FormHelperText>
        </FormControl>
        <FormControl fullWidth sx={{ m: 1 }} variant="standard">
          <InputLabel htmlFor="component-simple">
            {t('registration_number')}
          </InputLabel>
          <Input
            // id="component-simple"
            value={formData.number}
            onChange={handleChange}
            name="number"
            aria-describedby="standard-helper-text"
            inputProps={{
              "aria-label": "text",
            }}
            // error={formValidationError.number}
          />
          <FormHelperText id="standard-helper-text">
            {t('must_10_12_char')}
          </FormHelperText>
        </FormControl>

        <FormControl fullWidth sx={{ m: 1 }} variant="standard">
          <InputLabel htmlFor="component-simple">
            {t('model')}
          </InputLabel>
          <Input
            // id="component-simple"
            value={formData.model}
            onChange={handleChange}
            name="model"
            aria-describedby="standard-helper-text"
            inputProps={{
              "aria-label": "text",
            }}
            // error={formValidationError.number}
          />
          <FormHelperText id="standard-helper-text">
            {t('must_10_12_char')}
          </FormHelperText>
        </FormControl>

        <FormControl fullWidth sx={{ m: 1 }} variant="standard">
          <InputLabel htmlFor="component-simple">
            {t('factory')}
          </InputLabel>
          <Input
            // id="component-simple"
            value={formData.factory}
            onChange={handleChange}
            name="factory"
            aria-describedby="standard-helper-text"
            inputProps={{
              "aria-label": "text",
            }}
            // error={formValidationError.number}
          />
          {/* <FormHelperText id="standard-helper-text">
            {t('must_10_12_char')}
          </FormHelperText> */}
        </FormControl>
        
        <FormControl fullWidth sx={{ m: 1 }} variant="standard">
          <InputLabel htmlFor="component-simple">
            {t('key')}
          </InputLabel>
          <Input
            id="component-simple"
            value={formData.key}
            onChange={handleChange}
            name="key"
            aria-describedby="standard-helper-text"
            inputProps={{
              "aria-label": "text",
            }}
            // error={formValidationError.key}
          />
          <FormHelperText id="standard-helper-text">
            {t('must_not_empty')}
          </FormHelperText>
        </FormControl>
        <FormControl fullWidth sx={{ m: 1 }} variant="standard">
          {/* <InputLabel htmlFor="input-operator">
            {t('Operator')}
          </InputLabel> */}
          <TextField
            id='input-operator'
            select
            label={t('Operator')}
            name='point'
            value={formData.point}
            onChange={handleChange}
            helperText={t('select_report_type')}
            fullWidth
            variant='standard'
          >
            {OPERATORS.map((operator, index) => (
                <MenuItem
                    key={`${operator}`}
                    value={operator}
                >
                  {operator}
                </MenuItem>
            ))}
          </TextField>
          <FormHelperText id="standard-helper-text">
            {t('must_not_empty')}
          </FormHelperText>
        </FormControl>
        <FormControl>
          <Button
              variant='outlined'
              sx={{
                  marginTop: 2,
                  width: '100%',
              }}
              onClick={handleSubmit}
          >
              {t('submit')}
          </Button>
        </FormControl>
      </Grid>
      <Snackbar
        open={openSuccess}
        autoHideDuration={6000}
        onClose={handleClose}
      >
        <Alert
          onClose={handleClose}
          severity="success"
          sx={{ width: "100%" }}
        >
          Cash register saved!
        </Alert>
      </Snackbar>
      <Snackbar
        open={error.status}
        autoHideDuration={6000}
        onClose={handleClose}
      >
        <Alert
          onClose={handleClose}
          severity="error"
          sx={{ width: "100%" }}
        >
          {error.msg}
        </Alert>
      </Snackbar>
    </Grid>
    </>
  );
}

export default CashRegisterForm;
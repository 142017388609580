import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';

import styled from '@emotion/styled';

import Box from '@mui/material/Box';
import MenuList from '@mui/material/MenuList';
import MenuItem from '@mui/material/MenuItem';
import ListItemText from '@mui/material/ListItemText';

import { useNavigate, useLocation } from 'react-router-dom';

import { ROLE } from '../../constants';

export default function Menu({ role }) {
    let navigate = useNavigate();
    const { t } = useTranslation();
    const { pathname } = useLocation();
    const [colorsBG, setColorsBG] = useState({
        newReceipt: '#6b6d78',
        reports: '#313238',
        cashAndCashiers: '#313238',
        cashLogs: '#313238',
        serviceReceipts: '#313238',
    });

    const setBGcolor = target => {
        let newColors = {};
        Object.keys(colorsBG).map(key => {
            if (key === target) {
                newColors[key] = '#6b6d78';
            } else {
                newColors[key] = '#313238';
            }
        });
        setColorsBG(newColors);
    };

    return (
        <Box
            sx={{
                backgroundColor: '#313238',
                height: '100%',
                position: 'fixed',
            }}
        >
            <Menus sx={{ marginTop: 2 }}>
                <MenuItem
                    sx={{ backgroundColor: colorsBG.newReceipt }}
                    selected={pathname.includes('/acps/new-receipt')}
                >
                    <ListItemText
                        sx={{ color: '#FFFFFF' }}
                        onClick={() => {
                            setBGcolor('newReceipt');
                            navigate('/acps/new-receipt');
                        }}
                    >
                        {t('create_new_receipt')}
                    </ListItemText>
                </MenuItem>
                <MenuItem
                    sx={{ backgroundColor: colorsBG.reports }}
                    selected={pathname.includes('/acps/reports')}
                >
                    <ListItemText
                        sx={{ color: '#FFFFFF' }}
                        onClick={() => {
                            setBGcolor('reports');
                            navigate('/acps/reports');
                        }}
                    >
                        {t('cash_report')}
                    </ListItemText>
                </MenuItem>
                <MenuItem
                    sx={{ backgroundColor: colorsBG.cashLogs }}
                    selected={pathname.includes('/acps/cash-logs')}
                >
                    <ListItemText
                        sx={{ color: '#FFFFFF' }}
                        onClick={() => {
                            setBGcolor('cashLogs');
                            navigate('/acps/cash-logs');
                        }}
                    >
                        {t('check_journal')}
                    </ListItemText>
                </MenuItem>
                <MenuItem
                    sx={{ backgroundColor: colorsBG.serviceReceipts }}
                    selected={pathname.includes('/acps/service-receipts')}
                >
                    <ListItemText
                        sx={{ color: '#FFFFFF' }}
                        onClick={() => {
                            setBGcolor('serviceReceipts');
                            navigate('/acps/service-receipts');
                        }}
                    >
                        {t('service_checks')}
                    </ListItemText>
                </MenuItem>
                {role === ROLE.director && (
                    <MenuItem
                        sx={{
                            backgroundColor: colorsBG.serviceReceipts,
                            '&.Mui-selected': {
                                backgroundColor:
                                    'rgba(255, 255, 255, 0.5) !important',
                            },
                        }}
                        selected={pathname.includes('/acps/users')}
                    >
                        <ListItemText
                            sx={{ color: '#FFFFFF' }}
                            onClick={() => {
                                setBGcolor('serviceReceipts');
                                navigate('/acps/users');
                            }}
                        >
                            {t('users')}
                        </ListItemText>
                    </MenuItem>
                )}
                <MenuItem
                    sx={{
                        backgroundColor: colorsBG.serviceReceipts,
                        '&.Mui-selected': {
                            backgroundColor:
                                'rgba(255, 255, 255, 0.5) !important',
                        },
                    }}
                    selected={pathname === '/acps/taxes'}
                >
                    <ListItemText
                        sx={{ color: '#FFFFFF' }}
                        onClick={() => {
                            setBGcolor('serviceReceipts');
                            navigate('/acps/taxes');
                        }}
                    >
                        {t('TVA_taxes')}
                    </ListItemText>
                </MenuItem>
            </Menus>
        </Box>
    );
}

const Menus = styled(MenuList)`
    .MuiMenuItem-root {
        background-color: transparent !important;
    }
    .Mui-selected {
        background-color: rgba(255, 255, 255, 0.5) !important;
    }
`;

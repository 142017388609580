import React, { useState, useEffect } from 'react';
import Cookies from 'js-cookie';
import { useNavigate } from 'react-router-dom';

import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import CircularProgress from '@mui/material/CircularProgress';

import Menu from './clients_components/Menu';
import NewReceipt from './clients_components/NewReceipt';
import Reports from './clients_components/Reports';
import CashLogs from './clients_components/CashLogs';
import Report from './clients_components/Report';
import Receipt from './clients_components/Receipt';
// import CashAndCashiers from './clients_components/CashAndCashiers';
// import UserList from './clients_components/UserList';
// import NewUser from './clients_components/NewUser';
// import EditUser from './clients_components/EditUser';

// import { getCurrentOrganization } from '../services/requests/GraphRequests';
// import { getCurrentUser } from '../services/requests/GraphRequests';

import { Routes, Route } from 'react-router-dom';
import ServiceReceipts from './clients_components/ServiceReceipts';
import ServiceReceipt from './clients_components/ServiceReceipt';
import NewServiceReceipt from './clients_components/NewServiceReceipt';
import Taxes from './clients_components/Taxes';

export default function ClientOffice() {
    const [organization, setOrganization] = useState({});
    const [userName, setUserName] = useState('');
    const [userId, setUserId] = useState('');
    const [userCashRegisters, setUserCashRegisters] = useState([]);
    const [loading, setLoading] = useState(true);
    const [role, setRole] = useState('');
    let navigate = useNavigate();
    let cookieRole = Cookies.get('role');
    const hasSimpalsIdAuth = !!Cookies.get('simpalsid.auth');

    useEffect(() => {
        // if (!hasSimpalsIdAuth) {
        //     navigate('/');
        // } else {
        //     getCurrentOrganization()
        //         .then(data => {
        //             setOrganization(data.data.getCurrentInfo.organization);
        //             getCurrentUser()
        //                 .then(data => {
        //                     setUserName(data.data.getCurrentInfo.account.login);
        //                     setUserId(data.data.getCurrentInfo.user.id);
        //                     setUserCashRegisters(
        //                         data.data.getCurrentInfo.user.cashRegisters,
        //                     );
        //                     setLoading(false);
        //                 })
        //                 .catch(err => {
        //                     console.error(err);
        //                 });
        //         })
        //         .catch(err => {
        //             console.error(err);
        //         });
        // }
    }, [hasSimpalsIdAuth, navigate]);

    useEffect(() => {
        // if (cookieRole) {
        //     setRole(cookieRole);
        // }
    }, [cookieRole]);

    const [organizationAndUser, setOrganizationAndUser] = useState({});
    useEffect(() => {
        // setOrganizationAndUser({
        //     organization: organization,
        //     user: userName,
        //     userId: userId,
        //     userCashRegisters: userCashRegisters,
        // });
    }, [organization, userName, userId, userCashRegisters]);

    if (!loading) {
        return (
            <Box
                sx={{
                    width: '100%',
                    height: '90%',
                    position: 'absolute',
                    marginTop: 12,
                }}
            >
                <div align='center'>
                    <CircularProgress />
                </div>
            </Box>
        );
    }

    const NoOpPage = () => null;

    return (
        <Box
            sx={{
                width: '100%',
                height: '99%',
                position: 'absolute',
                marginTop: 9,
            }}
        >
            <Grid container spacing={2} align='left'>
                <Grid item xs={2}>
                    <Menu role={role} />
                </Grid>

                <Grid item xs={10}>
                    <Routes>
                        <Route
                            path='new-receipt'
                            element={
                                <NewReceipt
                                    organizationAndUser={organizationAndUser}
                                    role={role}
                                    userCashRegisters={userCashRegisters}
                                />
                            }
                        />
                        <Route
                            path='cash-and-cashiers'
                            // element={
                            //     <CashAndCashiers organization={organization} />
                            // }
                            element={<NoOpPage />}
                        />
                        <Route
                            path='reports'
                            element={<Reports organization={organization} />}
                        />
                        <Route
                            path='reports/:pageNumber'
                            element={<Reports organization={organization} />}
                        />
                        <Route
                            path='cash-logs'
                            element={<CashLogs organization={organization} />}
                        />
                        <Route
                            path='cash-logs/:pageNumber'
                            element={<CashLogs organization={organization} />}
                        />
                        <Route
                            path='report'
                            element={<Report />}
                            // element={<NoOpPage />}
                        >
                            <Route
                                path=':reportId'
                                // element={<Report />}
                                element={<NoOpPage />}
                            />
                        </Route>
                        <Route
                            path='receipt'
                            element={
                                <Receipt
                                    organizationAndUser={organizationAndUser}
                                />
                            }
                        >
                            <Route
                                path=':receiptId'
                                // element={
                                //     <Receipt
                                //         organizationAndUser={
                                //             organizationAndUser
                                //         }
                                //     />
                                // }
                                element={<NoOpPage />}
                            />
                        </Route>
                        <Route
                            path='service-receipts'
                            element={
                                <ServiceReceipts
                                    organization={organization}
                                    role={role}
                                />
                            }
                        />
                        <Route
                            path='service-receipts/:pageNumber'
                            element={
                                <ServiceReceipts
                                    organization={organization}
                                    role={role}
                                />
                            }
                        />
                        <Route
                            path='new-service-receipt'
                            element={
                                <NewServiceReceipt
                                    organizationAndUser={organizationAndUser}
                                />
                            }
                        />
                        <Route
                            path='service-receipt'
                            element={<ServiceReceipt />}

                        >
                            <Route
                                path=':receiptId'
                                element={<ServiceReceipt />}
                            />
                        </Route>
                        <Route
                            path='users'
                            // element={<UserList role={role} />}
                            element={<NoOpPage />}
                        />
                        <Route
                            path='users/:pageNumber'
                            // element={<UserList role={role} />}
                            element={<NoOpPage />}
                        />
                        <Route
                            path='edit-user/:userId'
                            // element={
                            //     <EditUser
                            //         organization={organization}
                            //         role={role}
                            //     />
                            // }
                            element={<NoOpPage />}
                        />
                        <Route
                            path='new-user'
                            // element={
                            //     <NewUser
                            //         organization={organization}
                            //         role={role}
                            //     />
                            // }
                            element={<NoOpPage />}
                        />
                        <Route
                            path='taxes'
                            element={
                                <Taxes
                                    organization={organization}
                                    role={role}
                                    userCashRegisters={userCashRegisters}
                                />
                            }
                        />
                    </Routes>
                </Grid>
            </Grid>
        </Box>
    );
}

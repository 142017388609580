/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import TextField from '@mui/material/TextField';
import MenuItem from '@mui/material/MenuItem';
import Button from '@mui/material/Button';
import CircularProgress from '@mui/material/CircularProgress';
import Alert from '@mui/material/Alert';
import Snackbar from '@mui/material/Snackbar';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { Pagination } from '@mui/material';

import usePageNavigation from '../../hooks/usePageNavigation';
import CashRegisterForm from './CashRegisterForm';


import {
    createNewReport,
    getAllMyReports,
} from '../../services/requests/ApiRequests';
import DatePickerRange from './DatePicker';

import { queryLimit, REPORT_TYPES } from '../../constants';
import { getQueryParams } from '../../utils';


function Reports(props) {
    let navigate = useNavigate();
    const { t } = useTranslation();
    const { pageNumber = '1' } = useParams();
    const [sortingDates, setSortingDates] = useState({
        dateFrom: null,
        dateTo: null,
    });
    const [reports, setReports] = useState([]);
    const [loading, setLoading] = useState(true);
    const [openSuccess, setOpenSuccess] = useState(false);
    const [error, setError] = useState({
        status: false,
        msg: 'Error',
    });
    const [selectedCashRegister, setSelectedCashRegister] = useState('');
    const [selectedReportType, setSlectedReportType] = useState('1'); // TODO: required? compare with ekassa
    const [selectedCashierId, setSelectedCashierId] = useState('');
    const [selectedCashierName, setSelectedCashierName] = useState('');
    const [reportBthVisibility, setReportBthVisibility] = useState(false);
    const [currentPage, setCurrentPage] = useState(1);
    const [pageCount, setPageCount] = useState(null);
    const [reportStart, setReportStart] = useState(0);
    const [card, setCard] = useState({});
    const [organization, setOrganization] = useState(null);
    const redirectLink = 'acps/reports';

    usePageNavigation(
        setCurrentPage,
        setReportStart,
        pageNumber,
        queryLimit,
        pageCount,
        navigate,
        redirectLink,
    );

    const isCashRegisterReadyToSubmit = !!organization;

    const onSubmitCashRegister = data => {
        setCard(data.formData);
        setOrganization(data.organization);
    };

    const handlePagination = (event, page) => {
        navigate(`/${redirectLink}/${page}`);
    };

    const getReports = () => {
        const params = {
            report_type: selectedReportType,
            limit: queryLimit,
            start: reportStart,
            date_from: sortingDates.dateFrom,
            date_to: sortingDates.dateTo,
            ...card,
            type: 3,
        };
        getAllMyReports(params).then(data => {
            setReports(data.data.data);
            setPageCount(Math.ceil(data.data.count / queryLimit));
            setLoading(false);
        });
    };

    useEffect(() => {
        if (!organization) return;
        getReports();
    }, [
        JSON.stringify(sortingDates),
        selectedCashierId,
        selectedCashierName,
        selectedReportType,
        selectedCashRegister,
        currentPage,
        organization,
    ]);

    useEffect(() => {
        if (organization) {
            setReportBthVisibility(true);
        } else {
            setReportBthVisibility(false);
        }
    }, [organization]);

    function generateReport(type) {
        const reqBody = {
            card,
            type,
        };

        createNewReport(reqBody).then(data => {
            if (data.data?.data?.length) {
                setLoading(true);
                getReports();
            } else {
                setError({
                    status: true,
                    msg: 'There are errors while report generation',
                });
            }
        });
    }

    function handleChangeCash(event) {
        setSelectedCashRegister(event.target.value);
    }

    const handleChangeReportType = event => {
        const inputValue =
            event.target.value === 'all' ? '' : event.target.value;
        setSlectedReportType(inputValue);
    };

    const handleClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }

        setOpenSuccess(false);
        setError({
            status: false,
            msg: 'Error',
        });
    };

    // if (loading) {
    //     return (
    //         <div align='center'>
    //             <CircularProgress />
    //         </div>
    //     );
    // }

    // if (!organization?.cashRegisters?.length) return null;

    return (
        <Box sx={{ flexGrow: 1, marginTop: 3, marginLeft: 0, marginRight: 3 }}>
            <CashRegisterForm
                onSubmit={onSubmitCashRegister}
            />
            <Grid container spacing={2} align='left'>
                <Grid item xs={6} align='center' justifyContent='column'>
                    <Grid
                        justifyContent='space-around'
                        display='flex'
                        sx={{ marginBottom: 1, marginTop: 2 }}
                    >
                        <Button
                            variant='outlined'
                            onClick={() => generateReport(REPORT_TYPES.Z)}
                            disabled={!reportBthVisibility}
                        >
                            {t('z_report')}
                        </Button>
                        <Button
                            variant='outlined'
                            onClick={() => generateReport(REPORT_TYPES.X)}
                            disabled={!reportBthVisibility}
                        >
                            {t('x_report')}
                        </Button>
                    </Grid>
                </Grid>
            </Grid>
            <Grid container spacing={2} alignItems='center'>
                <Grid item xs={3} align='center'>
                    <DatePickerRange
                        setSortingDates={setSortingDates}
                        isCashSelected={isCashRegisterReadyToSubmit}
                    />
                </Grid>
                <Grid item xs={3} align='center'>
                    {isCashRegisterReadyToSubmit && (
                        <TextField
                            id='input-report-type'
                            select
                            label={t('reports_type')}
                            name='reportsType'
                            value={selectedReportType}
                            onChange={handleChangeReportType}
                            helperText={t('select_report_type')}
                            fullWidth
                            sx={{ m: 1 }}
                            variant='standard'
                        >
                            {Object.keys(REPORT_TYPES).map((type, index) => (
                                <MenuItem
                                    key={`${type + index}`}
                                    value={`${REPORT_TYPES[type]}`}
                                >
                                    {`${type}`}
                                </MenuItem>
                            ))}
                        </TextField>
                    )}
                </Grid>
                {/* <Grid item xs={3} align='center'>
                    {isCashRegisterReadyToSubmit && (
                        <TextField
                            label={t('cashier_id')}
                            name='casheirId'
                            value={selectedCashierId}
                            onChange={event => {
                                setSelectedCashierName('');
                                setSelectedCashierId(event.target.value);
                            }}
                            helperText={t('type_cashier_id')}
                            fullWidth
                            sx={{ m: 1 }}
                            variant='standard'
                        />
                    )}
                </Grid>
                <Grid item xs={3} align='center'>
                    {isCashRegisterReadyToSubmit && (
                        <TextField
                            label={t('cashier_name')}
                            name='cashierName'
                            value={selectedCashierName}
                            onChange={event => {
                                setSelectedCashierId('');
                                setSelectedCashierName(event.target.value);
                            }}
                            helperText={t('type_cashier_name')}
                            fullWidth
                            sx={{ m: 1 }}
                            variant='standard'
                        />
                    )}
                </Grid> */}
            </Grid>

            <Grid container spacing={2} align='left'>
                <Grid item xs={12}>
                    <TableContainer component={Paper}>
                        <Table sx={{ minWidth: 650 }} aria-label='simple table'>
                            <TableHead>
                                <TableRow>
                                    <TableCell>{t('id')}</TableCell>
                                    <TableCell align='right'>
                                        {t('report')} #
                                    </TableCell>
                                    <TableCell align='right'>
                                        {t('date_time')}
                                    </TableCell>
                                    <TableCell align='right'>
                                        {t('total_mdl')}
                                    </TableCell>
                                    <TableCell align='right'>
                                        {t('total_year_mdl')}
                                    </TableCell>
                                    <TableCell align='right'>
                                        {t('status')}
                                    </TableCell>
                                    <TableCell align='right'>
                                        {t('type')}
                                    </TableCell>
                                    <TableCell align='right'>
                                        {t('id_cashier')}
                                    </TableCell>
                                    <TableCell align='right'>
                                        {t('cashier_name')}
                                    </TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {reports.map(report => {
                                    return (
                                        <TableRow
                                            hover
                                            key={report._id}
                                            sx={{
                                                'td, th': { cursor: 'pointer' },
                                                '&:last-child td, &:last-child th':
                                                    { border: 0 },
                                            }}
                                            onClick={() => {
                                                navigate(
                                                    `/acps/report/${report._id}?${getQueryParams(card)}`,
                                                );
                                            }}
                                        >
                                            <TableCell align='left'>
                                                {report._id}
                                            </TableCell>
                                            <TableCell align='right'>
                                                {report.soap_data.report}
                                            </TableCell>
                                            <TableCell align='right'>
                                                {report.soap_data.datetime}
                                            </TableCell>
                                            <TableCell align='right'>
                                                {report.soap_data.daily.total}
                                            </TableCell>
                                            <TableCell align='right'>
                                                {report.soap_data.year.total}
                                            </TableCell>
                                            <TableCell align='right'>
                                                {report.status}
                                            </TableCell>
                                            <TableCell align='right'>
                                                {report.soap_data.type}
                                            </TableCell>
                                            <TableCell align='right'>
                                                {report.cashier &&
                                                report.cashier.id !== null
                                                    ? report.cashier.id
                                                    : ' '}
                                            </TableCell>
                                            <TableCell align='right'>
                                                {report.cashier &&
                                                report.cashier.name
                                                    ? report.cashier.name
                                                    : ' '}
                                            </TableCell>
                                        </TableRow>
                                    );
                                })}
                            </TableBody>
                        </Table>
                    </TableContainer>
                </Grid>
                {pageCount > 1 && (
                    <Grid item xs={11} align='center'>
                        <Pagination
                            sx={{
                                my: 5,
                                justifyContent: 'center',
                                display: 'flex',
                            }}
                            count={pageCount}
                            variant='outlined'
                            shape='rounded'
                            page={+currentPage}
                            onChange={handlePagination}
                        />
                    </Grid>
                )}
            </Grid>
            <Snackbar
                open={openSuccess}
                autoHideDuration={6000}
                onClose={handleClose}
            >
                <Alert
                    onClose={handleClose}
                    severity='success'
                    sx={{ width: '100%' }}
                >
                    {t('raport_creat')}
                </Alert>
            </Snackbar>
            <Snackbar
                open={error.status}
                autoHideDuration={6000}
                onClose={handleClose}
            >
                <Alert
                    onClose={handleClose}
                    severity='error'
                    sx={{ width: '100%' }}
                >
                    {error.msg}
                </Alert>
            </Snackbar>
        </Box>
    );
}
export default Reports;

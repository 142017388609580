import React, { useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { useReactToPrint } from 'react-to-print';

import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import CircularProgress from '@mui/material/CircularProgress';
import Grid from '@mui/material/Grid';
import Card from '@mui/material/Card';
import Divider from '@mui/material/Divider';
import CardContent from '@mui/material/CardContent';
import Button from '@mui/material/Button';
import PrintIcon from '@mui/icons-material/Print';

import { useNavigate, useParams, useSearchParams } from "react-router-dom";

import { getServiceReceiptById } from '../../services/requests/ApiRequests';

export default function ServiceReceipt() {
  let params = useParams();
  const { t } = useTranslation();

  let navigate = useNavigate();
  const componentRef = useRef();
  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
  });

  const [number, setNumber] = React.useState("000000");
  // const [id, setId] = React.useState({id: params.receiptId});

  const [receipt, setReceipt] = React.useState({});
  const [loading, setLoading] = React.useState(true);

  const [searchParams] = useSearchParams();
  const searchParamsObj = Object.fromEntries(searchParams);

  React.useEffect(() => {
    getServiceReceiptById(params.receiptId, searchParamsObj)
    .then((data) => {
      setReceipt(data.data);
      setLoading(false);
      var str = data.data.soap_data.id.toString();
      let pad = "000000";
      let new_number = pad.substring(0, pad.length - str.length) + str;
      setNumber(new_number);
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

  if (loading) {
    return (
      <div align='center'>
          <CircularProgress />
      </div>
    )
  }
  return (
    <Box sx={{ flexGrow: 1, marginTop: 5, marginLeft: 10 }}>
      <Grid container spacing={2} align='left'>
        <Grid item xs={5}>
          <Typography sx={{ fontSize: 28 }} color="pimary" gutterBottom>
            {t('viewing_service_receipt')}
          </Typography>
          <Button variant="text" color="secondary" onClick={() => { navigate("/acps/service-receipts");}}>&#60; {t('back_to_service_receipt_journal_btn')}</Button>
          <Divider />
          <Grid container spacing={2} align='left'>
            <Grid item xs={8}>
              <Typography sx={{ fontSize: 20 }} color="pimary" gutterBottom>
                {t('information_about_check')}
              </Typography>
            </Grid>
            <Grid item xs={4}>
              <Typography sx={{ fontSize: 20 }} color="pimary" gutterBottom>
                {receipt.status}
              </Typography>
            </Grid>

            <Grid item xs={6}>
              <Typography sx={{ fontSize: 14 }} color="text.secondary" gutterBottom>
                {t('receipt_number')}:
              </Typography>
            </Grid>
            <Grid item xs={6}>
              <Typography sx={{ fontSize: 14 }} color="pimary" gutterBottom>
                {number}
              </Typography>
            </Grid>

            <Grid item xs={6}>
              <Typography sx={{ fontSize: 14 }} color="text.secondary" gutterBottom>
                {t('formed_date')}:
              </Typography>
            </Grid>
            <Grid item xs={6}>
              <Typography sx={{ fontSize: 14 }} color="pimary" gutterBottom>
                {receipt.soap_data.datetime.split('T')[0]}  {receipt.soap_data.datetime.split('T')[1]}
              </Typography>
            </Grid>         

            <Grid item xs={6}>
              <Typography sx={{ fontSize: 14 }} color="text.secondary" gutterBottom>
                {t('kkm_name')}:
              </Typography>
            </Grid>
            <Grid item xs={6}>
              <Typography sx={{ fontSize: 14 }} color="pimary" gutterBottom>
                {receipt.soap_data.card.name}
              </Typography>
            </Grid>           

            <Grid item xs={6}>
              <Typography sx={{ fontSize: 14 }} color="text.secondary" gutterBottom>
                {t('serial_number_kkm')}:
              </Typography>
            </Grid>
            <Grid item xs={6}>
              <Typography sx={{ fontSize: 14 }} color="pimary" gutterBottom>
                {receipt.soap_data.card.number}
              </Typography>
            </Grid>

            <Grid item xs={6}>
              <Typography sx={{ fontSize: 14 }} color="text.secondary" gutterBottom>
                {t('address_kkm')}:
              </Typography>
            </Grid>
            <Grid item xs={6}>
              <Typography sx={{ fontSize: 14 }} color="pimary" gutterBottom>
                {receipt.soap_data.card.address}
              </Typography>
            </Grid>

            <Grid item xs={12} align='center'>

              <Button variant="outlined" sx={{ width: '75%' }} startIcon={<PrintIcon />} onClick={handlePrint}>
                {t('print_receipt')}
              </Button>
            </Grid>
          </Grid>


        </Grid>
        <Grid item xs={6}>
          <Card sx={{ width: 375 }} id='receipt' ref={componentRef}>
              <CardContent>

                  <Typography variant="h5" component="h5" color="text.secondary" align='center'>
                  {receipt.soap_data.card.name}
                  </Typography>
                  <Typography sx={{ fontSize: 14 }} color="text.secondary" align='center' gutterBottom>
                  COD FISCAL: {receipt.soap_data.card.idnx}
                  </Typography>
                  <Typography sx={{ fontSize: 14 }} color="text.secondary" align='center' gutterBottom>
                  {receipt.soap_data.card.address}
                  </Typography>

                  <Grid container spacing={2} align='left'>
                            <Grid item xs={8}>
                                <Typography sx={{ fontSize: 14 }} color="text.secondary" gutterBottom>
                                NUMARUL DE INREGISTRARE:
                                </Typography>
                            </Grid>
                            <Grid item xs={4} align='right'>
                                <Typography sx={{ fontSize: 14 }} color="text.secondary" gutterBottom>
                                {receipt.soap_data.card.number}
                                </Typography>
                            </Grid>
                        </Grid>

                        <Grid container spacing={2} align='left'>
                            <Grid item xs={8}>
                                <Typography sx={{ fontSize: 14 }} color="text.secondary" gutterBottom>
                                SN:
                                </Typography>
                            </Grid>
                            <Grid item xs={4} align='right'>
                                <Typography sx={{ fontSize: 14 }} color="text.secondary" gutterBottom>
                                {receipt.soap_data.card.factory}
                                </Typography>
                            </Grid>
                        </Grid>

                  <Divider />
                  <Grid container spacing={2} align='left'>
                      <Grid item xs={8}>
                          <Typography sx={{ fontSize: 14, fontWeight: 600  }} color="text.secondary" gutterBottom>
                          {receipt.soap_data.data.type === 'OUTPUT' && 
                            <>IESIRE SERTAR</>
                          }
                          {receipt.soap_data.data.type === 'INPUT' && 
                            <>INTRARI SERTAR</>
                          }
                          </Typography>
                      </Grid>
                      <Grid item xs={4} align='right'>
                          <Typography sx={{ fontSize: 14, fontWeight: 600  }} color="text.secondary" gutterBottom>
                          {receipt.soap_data.data.total}
                          </Typography>
                      </Grid>
                  </Grid>

                  <Grid container spacing={2} align='left'>
                      <Grid item xs={12} align='left'>
                          <Typography sx={{ fontSize: 14 }} color="text.secondary" gutterBottom>
                          {number}
                          </Typography>
                      </Grid>
                  </Grid>

                  <Divider />
                  <Grid container spacing={2} align='left'>
                      <Grid item xs={8}>
                          <Typography sx={{ fontSize: 14 }} color="text.secondary" gutterBottom>
                            DATA: {receipt.soap_data.datetime.split('T')[0]}
                          </Typography>
                      </Grid>
                      <Grid item xs={4} align='right'>
                          <Typography sx={{ fontSize: 14 }} color="text.secondary" gutterBottom>
                            ORA: {receipt.soap_data.datetime.split('T')[1]}
                          </Typography>
                      </Grid>
                  </Grid>

                  <Divider />

                  <Grid container spacing={2} align='center'>
                      <Grid item xs={12}>
                          <Typography sx={{ fontSize: 14 }} color="text.secondary" gutterBottom>
                            ***  BON DE SERVICIU  ***
                          </Typography>
                      </Grid>
                  </Grid>

              </CardContent>

          </Card>
        </Grid>
      </Grid>
    </Box>
  )
}
